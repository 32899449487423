import { Flex, Box, Grid, GridItem, Container } from "@chakra-ui/react";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import state from "./number-panel-state";
import { fonts } from "../../../mapping";
import { forwardRef } from "react";

function NumberPanel() {
  const { t } = useTranslation();
  const { selectedPlayerNumberFont } = useSnapshot(state);
  return (
    <Container
      flex={1}
      bgColor={"#f9f9f9"}
      borderWidth={"1px"}
      borderColor={"brand.900"}
      borderTopRightRadius={"xl"}
      borderEndStartRadius={"xl"}
      borderEndEndRadius={"xl"}
      padding={"1rem 1.5rem"}
      overflowY="scroll"
    >
      <Flex direction={"column"}>
        <Grid p={"0"} templateColumns="repeat(2, 1fr)" gap={6}>
          {fonts.map((item) => (
            <GridItem
              key={item.key}
              w="8rem"
              h="8rem"
              p={"4px"}
              borderRadius={"md"}
              boxShadow={"md"}
              userSelect={"none"}
            >
              <Box
                pos="relative"
                w="100%"
                h="100%"
                borderRadius={"md"}
                border={
                  selectedPlayerNumberFont === item.key ? "1px solid" : "0"
                }
                borderColor={"brand.900"}
                bgImage={`url(${item.thumbnail_number})`}
                bgSize={"cover"}
                onClick={() => (state.selectedPlayerNumberFont = item.key)}
              />
            </GridItem>
          ))}
        </Grid>
      </Flex>
    </Container>
  );
}
export default forwardRef(NumberPanel);
