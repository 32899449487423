import { VStack, Text, Box, Grid } from "@chakra-ui/react";
import { colors } from "../../mapping";

export default function StyledColorPicker(props) {
  return (
    <VStack alignItems={"flex-start"} justifyContent={"flex-start"} p={1}>
      {props.title && <Text color={"brand.900"}>{props.title}</Text>}
      <Grid templateColumns={"repeat(12, 1fr)"} gap={"8px"}>
        {colors.map((item, index) => {
          return (
            <Box
              key={item.key}
              boxSize={"18px"}
              bgColor={item.printingColorCode}
              borderRadius={"full"}
              boxShadow={"lg"}
              onClick={() => props.onValueChanged(index)}
            >
              <Box
                hidden={props.value !== index}
                pos="relative"
                right="4px"
                top="-4px"
                boxSize={"26px"}
                border={"1px solid"}
                borderColor={"brand.900"}
                borderRadius={"full"}
                p="4px"
              />
            </Box>
          );
        })}
      </Grid>
    </VStack>
  );
}
