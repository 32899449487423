import { useThree } from "@react-three/fiber";
import { OrbitControls, Html } from "@react-three/drei";
import MaleVB from "../models/Male_vb_final";
import FemaleVB from "../models/Female_vb_final";
import Football from "../models/Football_final";
import Basketball from "../models/Basketball_final";
import React, { useEffect, forwardRef } from "react";
import { CursorIcon } from "../../components/CustomIcons";
import MovableDIV from "../../components/StyledAnimatedDiv";

import { state } from "../../state";
import stylePanelState from "../../pages/panels/style-panel/style-panel-state";
import { useSnapshot } from "valtio";

function MainStage(props, ref) {
  const { camera } = useThree();
  const { page, selectedTab } = useSnapshot(state);
  const { selectedStyle, selectedPattern } = useSnapshot(stylePanelState);

  // Update orbit control when selected pattern is changed
  useEffect(() => {
    if (!ref.current) return;
    ref.current.setAzimuthalAngle((20 * Math.PI) / 180);
  }, [selectedPattern]);

  useEffect(() => {
    if (!ref.current) return;
    switch (page) {
      case 0:
        switch (selectedTab) {
          case 1:
          case 2:
            ref.current.setAzimuthalAngle((0 * Math.PI) / 180);
            break;
          case 3:
            ref.current.setAzimuthalAngle((180 * Math.PI) / 180);
            break;
          default:
            ref.current.setAzimuthalAngle((20 * Math.PI) / 180);
            break;
        }
        break;
      case 1:
        ref.current.setAzimuthalAngle((0 * Math.PI) / 180);
        break;
      case 2:
      default:
        // do nothing
        break;
    }
  }, [selectedTab, page, ref]);

  return (
    <>
      <OrbitControls
        enableRotate={page === 0}
        ref={ref}
        makeDefault
        camera={camera}
        enablePan={false}
        enableZoom={false}
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 2}
      />
      {selectedStyle === "football" && (
        <Football visible={selectedStyle === "football"} />
      )}
      {selectedStyle === "basketball" && (
        <Basketball visible={selectedStyle === "basketball"} />
      )}
      {selectedStyle === "female_vb" && (
        <FemaleVB visible={selectedStyle === "female_vb"} />
      )}
      {selectedStyle === "male_vb" && (
        <MaleVB visible={selectedStyle === "male_vb"} />
      )}
      {/* <PreloadFont /> */}
      <Html
        as="div"
        fullscreen
        prepend
        style={{
          display: page === 0 ? "flex" : "none",
          alignItems: "flex-end",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100px",
            height: "50px",
            // backgroundColor: "#0000001f",
          }}
        >
          <MovableDIV>
            <CursorIcon boxSize={28} />
          </MovableDIV>
        </div>
      </Html>
    </>
  );
}

export default forwardRef(MainStage);
