import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import Home from "./pages";
import theme from "./theme";
import { useEffect } from "react";
import { mapping } from "./mapping";
import stylePanelState from "./pages/panels/style-panel/style-panel-state";
import colorEditorState from "./pages/panels/color-editor-panel/color-editor-panel-state";
import { useSnapshot } from "valtio";
import { signIn } from "./firebase.config";

import "./i18n";

function App() {
  const { selectedPattern } = useSnapshot(stylePanelState);
  useEffect(() => {
    signIn();
    const rtn = mapping.find((item) => item.key === selectedPattern);
    colorEditorState.selectedColorSet = JSON.parse(
      JSON.stringify(rtn.defaultColorSet)
    );
  });
  return (
    <ChakraProvider theme={theme}>
      <Home />
    </ChakraProvider>
  );
}

export default App;
