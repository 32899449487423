import { Textarea } from "@chakra-ui/react";
import { useRef, useState, forwardRef } from "react";

function StyledTextarea(props, ref) {
  const { onChange, value, ...otherProps } = props;

  // log if on composition
  const onComposition = useRef(false);
  // temp input
  const [inputValue, setInputValue] = useState(value);

  const _onChange = (event) => {
    setInputValue(event.target.value);

    // IME method start
    if (event.type === "compositionstart") {
      onComposition.current = true;
      return;
    }

    // IME method end
    if (event.type === "compositionend") {
      onComposition.current = false;
    }

    // handle parent onChange
    if (!onComposition.current) {
      onChange && onChange(event);
    }
  };

  return (
    <Textarea
      {...otherProps}
      ref={ref}
      value={inputValue}
      focusBorderColor="brand.900"
      onChange={_onChange}
      onCompositionEnd={_onChange}
      onCompositionStart={_onChange}
    />
  );
}

export default forwardRef(StyledTextarea);
