import React, { useRef, useEffect } from "react";

const MaterialLayer = ({
  geometry,
  map,
  colorApplied,
  opacity = 1,
  normalMap,
}) => {
  const materialLayerRef = useRef(null);

  useEffect(() => {
    if (map) {
      materialLayerRef.current.map = map;
      materialLayerRef.current.map.flipY = false;
      materialLayerRef.current.map.needsUpdate = true;
    }

    materialLayerRef.current.normalMap = normalMap;
  }, [map, normalMap]);

  return (
    <mesh geometry={geometry}>
      <meshStandardMaterial
        ref={materialLayerRef}
        // normalMap={normalMap}
        color={colorApplied}
        roughness={0.5}
        transparent={true}
        opacity={opacity}
      />
    </mesh>
  );
};

export default MaterialLayer;
