import styled, { keyframes, css } from "styled-components";

const move = keyframes`
      0% {
        transform: translateX(-2em);
      }
      50% {
        transform: translateX(2em);
      }
      100% {
        transform: translateX(-2em);
      }
  `;

const moveRules = css`${move} 2s infinite}`;
const MoveableDIV = styled.div`
  animation: ${moveRules};
`;

export default MoveableDIV;
