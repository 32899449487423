import { proxy } from "valtio";

const submitState = proxy({
  // Form Data
  isUploadingRefImage: false,
  isUploadingCSVFile: false,
  isSubmitting: false,
  submitFormData: {
    groupName: "",
    customerName: "",
    customerEmail: "",
    customerPhone: "",
    otherNotes: "",
    uploadFileUrl: "",
    uploadFilename: "",
    csvFileUrl: "",
  },
  frontImageBase64: "",
  backImageBase64: "",
  get canSubmit() {
    if (submitState.isUploadingRefImage) return false;
    if (submitState.isUploadingCSVFile) return false;
    if (submitState.isSubmitting) return false;
    return true;
  },
});

export default submitState;
