import { proxy } from "valtio";

const state = proxy({
  page: 0,
  selectedTab: 0,

  get panelTitle() {
    switch (state.selectedTab) {
      case 0:
        return "panels.panel_style";
      case 1:
        return "panels.panel_number";
      case 2:
        return "panels.panel_teamName";
      case 3:
        return "panels.panel_name";
      case 4:
        return "panels.panel_material";
      default:
        return "";
    }
  },

  get editorTitle() {
    switch (state.page) {
      default:
      case 0:
        return state.selectedTab === 4
          ? "editors.material_info"
          : "editors.editor_color";
      case 1:
        return "editors.batch_editor";
      case 2:
        return "editors.order_info";
    }
  },

  get canNextStep() {
    if (state.page === 1) {
      if (!state.numberOfRecord) return false;

      for (var key in state.records) {
        for (var key2 in state.records[key]) {
          if (
            state.records[key][key2] === null ||
            state.records[key][key2] === ""
          )
            return false;
        }
      }
    } else if (state.page === 2) {
      if (state.isUploadingRefImage) return false;
      if (state.isUploadingCSVFile) return false;
      if (state.isSubmitting) return false;
    }
    return true;
  },
});

export { state };
