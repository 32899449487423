import { proxy } from "valtio";

const batchEditorState = proxy({
  numberOfRecord: undefined,
  records: [],
  get validate() {
    if (!batchEditorState.numberOfRecord) return false;
    for (var key in batchEditorState.records) {
      for (var key2 in batchEditorState.records[key]) {
        if (
          batchEditorState.records[key][key2] === null ||
          batchEditorState.records[key][key2] === ""
        )
          return false;
      }
    }
    return true;
  },
});

export default batchEditorState;
