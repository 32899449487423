import { forwardRef, useState, useEffect } from "react";
import {
  Container,
  Box,
  VStack,
  Text,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  TableCaption,
  Tr,
  Th,
  Td,
  TableContainer,
  Select,
  FormControl,
} from "@chakra-ui/react";
import StyledInput from "../../../components/StyledInput";
import { useSnapshot } from "valtio";
import state from "./batch-panel-state";
import playerNamePanelState from "../player-name-panel/player-name-panel-state";
import { useTranslation } from "react-i18next";
import { DeleteIcon, TriangleDownIcon } from "@chakra-ui/icons";
import { sex, sizes } from "../../../mapping";
import submitState from "../submit-panel/submit-state";

let recordId = 0;
function BatchPanel(props, ref) {
  const { numberOfRecord, records } = useSnapshot(state);
  const { selectedPlayerNameFontLanguage } = useSnapshot(playerNamePanelState);
  const [tempNumberOfRecord, setTempNumberOfRecord] = useState("");
  const [isFirstTry, setFirstTry] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    screencap();
  }, []);

  const screencap = async () => {
    // Rotate to 0 and screen cap for the front view
    props.stageRef.current.setAzimuthalAngle((0 * Math.PI) / 180);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const frontImg = props.canvasRef.current.toDataURL();
    submitState.frontImageBase64 = frontImg;
  };

  const getTargetRecord = (records, curItem) =>
    records.find((target) => target.id === curItem.id);

  const checkRecordIsValid = (curItem) =>
    curItem.name !== "" &&
    curItem.number !== "" &&
    curItem.upperSize !== "" &&
    curItem.pantsSize !== "" &&
    curItem.sex !== "";

  const renderNumberOfRecordForm = () => {
    return (
      <VStack p={0} m={0} h={"100%"}>
        <Box backgroundColor={"brand.200"} w={"100%"} h={"50px"} />
        <Box>
          <Text mt={"14"} mb={"3"}>
            {t("batch_editor.input_number_of_record")}
          </Text>
          <FormControl isInvalid={!isFirstTry && tempNumberOfRecord === ""}>
            <Input
              mb="0.5rem"
              maxLength={2}
              placeholder={t("batch_editor.number_of_record_placeholder")}
              focusBorderColor="brand.900"
              border={0}
              bgColor={"white"}
              borderRadius={"md"}
              boxShadow={"md"}
              value={tempNumberOfRecord}
              onChange={(e) => {
                if (!isNaN(e.target.value.slice(-1))) {
                  if (e.target.value > 60) {
                    setTempNumberOfRecord(60);
                  } else {
                    setTempNumberOfRecord(e.target.value);
                  }
                }
              }}
            />
          </FormControl>
          <Text fontSize={"small"}>{t("batch_editor.record_limit_tips")}</Text>
          <Button
            mt={"9"}
            bgColor={"white"}
            color={"brand.900"}
            borderRadius={"16px"}
            padding={"0.5rem 8rem"}
            outline={"1px solid"}
            outlineColor={"brand.900"}
            boxShadow={"md"}
            onClick={() => {
              setFirstTry(false);
              if (tempNumberOfRecord === "") return;
              state.numberOfRecord = tempNumberOfRecord;
              for (let i = 0; i < tempNumberOfRecord; i++) {
                recordId = i;
                state.records.push({
                  id: recordId,
                  name: "",
                  number: "",
                  upperSize: "",
                  pantsSize: "",
                  sex: "",
                });
              }
            }}
          >
            {t("actions.confirm")}
          </Button>
        </Box>
      </VStack>
    );
  };

  const renderRecordTable = () => {
    return (
      <TableContainer overflowX="unset" overflowY="unset">
        <Table variant="simple" size="sm">
          <Thead
            bgColor={"brand.200"}
            height={"50px"}
            position="sticky"
            top={0}
            zIndex="docked"
          >
            <Tr>
              <Th color={"white"}>{t("batch_editor.header_id")}</Th>
              <Th color={"white"}>{t("batch_editor.header_name")}</Th>
              <Th color={"white"}>{t("batch_editor.header_number")}</Th>
              <Th color={"white"}>{t("batch_editor.header_upper_size")}</Th>
              <Th color={"white"}>{t("batch_editor.header_pants_size")}</Th>
              <Th color={"white"}>{t("batch_editor.header_sex")}</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody color={"brand.900"}>
            {records.map((item, index) => (
              <Tr
                key={`tr_key_${item.id}`}
                bgColor={checkRecordIsValid(item) ? "white" : "brand_danger.10"}
              >
                <Td>{index + 1}.</Td>
                <Td>
                  <FormControl
                    isInvalid={getTargetRecord(records, item).name === ""}
                  >
                    <StyledInput
                      width={"140px"}
                      maxLength={
                        selectedPlayerNameFontLanguage === "en" ? 18 : 10
                      }
                      placeholder={t("batch_editor.name_placeholder")}
                      border={0}
                      bgColor={"white"}
                      borderRadius={"md"}
                      boxShadow={"md"}
                      _placeholder={{ color: "brand.900" }}
                      value={getTargetRecord(records, item).name}
                      onChange={(e) => {}}
                      onBlur={(e) =>
                        (getTargetRecord(state.records, item).name =
                          e.target.value)
                      }
                    />
                  </FormControl>
                </Td>
                <Td>
                  <FormControl
                    isInvalid={getTargetRecord(records, item).number === ""}
                  >
                    <Input
                      width={"76px"}
                      maxLength={2}
                      placeholder={t("batch_editor.number_placeholder")}
                      focusBorderColor="brand.900"
                      border={0}
                      bgColor={"white"}
                      borderRadius={"md"}
                      boxShadow={"md"}
                      _placeholder={{ color: "brand.900" }}
                      value={getTargetRecord(records, item).number}
                      onChange={(e) => {
                        if (!isNaN(e.target.value.slice(-1))) {
                          getTargetRecord(state.records, item).number =
                            e.target.value;
                        }
                      }}
                      onBlur={(e) => {}}
                    />
                  </FormControl>
                </Td>
                <Td>
                  <FormControl
                    isInvalid={getTargetRecord(records, item).upperSize === ""}
                  >
                    <Select
                      fontSize={14}
                      boxShadow={"md"}
                      border={"0"}
                      color={"brand.900"}
                      bgColor={"white"}
                      icon={<TriangleDownIcon fontSize={10} />}
                      placeholder={t("batch_editor.size_placeholder")}
                      value={getTargetRecord(records, item).upperSize}
                      onChange={(e) =>
                        (getTargetRecord(state.records, item).upperSize =
                          e.target.selectedOptions[0].value)
                      }
                    >
                      {sizes.map((item) => (
                        <option
                          key={`upper_size_${item.key}`}
                          value={item.value}
                        >
                          {item.value.toUpperCase()}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Td>
                <Td>
                  <FormControl
                    isInvalid={getTargetRecord(records, item).pantsSize === ""}
                  >
                    <Select
                      fontSize={14}
                      boxShadow={"md"}
                      border={"0"}
                      color={"brand.900"}
                      bgColor={"white"}
                      icon={<TriangleDownIcon fontSize={10} />}
                      placeholder={t("batch_editor.size_placeholder")}
                      value={getTargetRecord(records, item).pantsSize}
                      onChange={(e) =>
                        (getTargetRecord(state.records, item).pantsSize =
                          e.target.selectedOptions[0].value)
                      }
                    >
                      {sizes.map((item) => (
                        <option
                          key={`pants_size_${item.key}`}
                          value={item.value}
                        >
                          {item.value.toUpperCase()}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Td>
                <Td>
                  <FormControl
                    isInvalid={getTargetRecord(records, item).sex === ""}
                  >
                    <Select
                      fontSize={14}
                      boxShadow={"md"}
                      border={"0"}
                      color={"brand.900"}
                      bgColor={"white"}
                      icon={<TriangleDownIcon fontSize={10} />}
                      placeholder={t("batch_editor.header_sex")}
                      value={getTargetRecord(records, item).sex}
                      onChange={(e) =>
                        (getTargetRecord(state.records, item).sex =
                          e.target.selectedOptions[0].value)
                      }
                    >
                      {sex.map((item) => (
                        <option key={`sex_${item.key}`} value={item.value}>
                          {t("sex." + item.value)}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Td>
                <Td>
                  <Button
                    size={"sm"}
                    boxShadow={"md"}
                    borderRadius={"lg"}
                    color={"white"}
                    bgColor={"brand_danger.100"}
                    onClick={() => {
                      state.records = state.records.filter(
                        (target) => target.id !== item.id
                      );
                      if (state.records.length <= 0)
                        state.numberOfRecord = undefined;
                    }}
                    _hover={{ bgColor: "brand_danger.100" }}
                    _active={{ bgColor: "brand_danger.100" }}
                  >
                    <DeleteIcon mr={"2"} />
                    {t("actions.delete")}
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
          <TableCaption>
            <Button
              hidden={state.records.length >= 60}
              mb={"5"}
              bgColor={"white"}
              color={"brand.900"}
              borderRadius={"16px"}
              padding={"0.5rem 20rem"}
              outline={"1px solid"}
              outlineColor={"brand.900"}
              boxShadow={"md"}
              onClick={() => {
                if (records.length < 60) {
                  const id = state.records[state.records.length - 1].id + 1;
                  state.records.push({
                    id: id,
                    name: "",
                    number: "",
                    upperSize: "",
                    pantsSize: "",
                    sex: "",
                  });
                }
              }}
              _hover={{ bgColor: "white" }}
              _active={{ bgColor: "white" }}
            >
              {t("batch_editor.add_one_record")}
            </Button>
          </TableCaption>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Container
      flex={1}
      bgColor={"#f9f9f9"}
      borderWidth={"1px"}
      borderColor={"brand.900"}
      borderTopRightRadius={"xl"}
      borderEndStartRadius={"xl"}
      borderEndEndRadius={"xl"}
      p={0}
      maxWidth={"100%"}
      overflowY={state.numberOfRecord ? "scroll" : "hidden"}
    >
      {numberOfRecord === undefined
        ? renderNumberOfRecordForm()
        : renderRecordTable()}
    </Container>
  );
}
export default forwardRef(BatchPanel);
