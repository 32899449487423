import React, { useRef, useEffect } from "react";
import {
  Decal,
  Text,
  RenderTexture,
  PerspectiveCamera,
} from "@react-three/drei";
import { fonts } from "../../../mapping";

const RenderNumber = ({
  baseMeshRef,
  numberTextRef,
  side,
  fontSelected,
  number = 10,
  fontColor = "white",
  isPant,
  position,
  scale = 1,
  offset = { h: 0, v: 0 },
}) => {
  return (
    <Decal
      position={
        position
          ? position
          : isPant
          ? [0.1, -0.38, 0.05]
          : [0, 0.095, side === "front" ? 0.15 : -0.15]
      }
      rotation={[0, side === "front" ? Math.PI : 0, 0]}
      // scale={isPant ? 0.12 : side === "front" ? 0.15 : 0.27}
      // scale={(isPant ? 0.08 : side === "front" ? 0.15 : 0.22) * scale}
      scale={isPant ? 0.08 : 0.24}
      mesh={baseMeshRef}
      // debug
    >
      <meshStandardMaterial
        roughness={0.6}
        transparent={true}
        opacity={1}
        polygonOffset
        polygonOffsetFactor={-5}
      >
        <RenderTexture attach="map" anisotropy={16}>
          <PerspectiveCamera
            makeDefault
            manual
            aspect={1 / 1}
            position={[0, 0, 1]}
          />
          {/* <Text
            font={font}
            rotation={[0, -Math.PI, 0]}
            position={[0, side === "front" ? 0.025 : 0, 0]}
            ref={numberTextRef}
            fontSize={0.8}
            color={fontColor}
            // strokeWidth={0.01}
          >
            {number}
          </Text> */}
          {fonts.map((f, idx) => (
            <Text
              key={idx}
              font={f.filepath}
              rotation={[0, -Math.PI, 0]}
              // position={[0, side === "front" ? 0.025 : 0, 0]}
              position={[0 + offset.h, 0 + offset.v, 0]}
              ref={numberTextRef}
              fontSize={0.9 * scale}
              // fontSize={1}
              color={fontColor}
              visible={f.key === fontSelected}
            >
              {number}
            </Text>
          ))}
        </RenderTexture>
      </meshStandardMaterial>
    </Decal>
  );
};

export default RenderNumber;
