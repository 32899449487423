import {
  Body,
  Container,
  Column,
  Head,
  Heading,
  Hr,
  Html,
  Link,
  Row,
  Section,
  Text,
} from "@react-email/components";
import * as React from "react";
import { colors, fonts, fabric } from "../mapping";

const pattern_parts = {
  upper: "Body",
  pants: "Shorts",
  sleeves: "Sleeve",
  collar: "Collar & Edge",
};

// Props:
// orderNumber
// orderDate
// groupName
// customerName
// email
// tel
// notes
// selectedStyle
// selectedPattern
// selectedColorSet
// patternCode
// selectedPlayerNameFont
// selectedPlayerNameColor
// selectedTeamNameFont
// teamNameText
// selectedTeamNameColor
// selectedPlayerNumberFont
// selectedPlayerNumberColorTop
// selectedPlayerNumberColorPants
// selectedFabric

const sportswear_styles = [
  { key: "足球衫", value: "football" },
  { key: "籃球衫", value: "basketball" },
  { key: "男排衫", value: "male_vb" },
  { key: "女排衫", value: "female_vb" },
];

export const DOSOrderEmailTemplate = (props) => {
  const getSortedSelectedColorSet = () => {
    return Object.keys(props.selectedColorSet)
      .sort((a, b) => {
        if (a.split("_")[0] === "upper") return -3;
        if (a.split("_")[0] === "sleeves") return -2;
        if (a.split("_")[0] === "collar") return -1;
        if (a.split("_")[0] === "pants") return 0;
      })
      .sort((a, b) => {
        if (a.split("_")[0] === b.split("_")[0]) {
          const aNum = parseInt(a.split("_")[1]);
          const bNum = parseInt(b.split("_")[1]);
          return aNum - bNum;
        }
        return 0;
      });
  };

  const renderStyleText = () => {
    if (!props.selectedStyle) return "";
    return sportswear_styles.find((item) => item.value === props.selectedStyle)
      .key;
  };
  const renderPatternText = () => {
    if (!props.selectedStyle || !props.selectedPattern || !props.patternCode)
      return "";

    const patternInArr = props.selectedPattern.split("_");
    const styleName = sportswear_styles.find(
      (item) => item.value === props.selectedStyle
    ).key;
    const patternNumber = patternInArr[patternInArr.length - 1];
    return `${styleName} ${patternNumber} (${props.patternCode})`;
  };

  const renderSelectedFont = (key) => {
    if (!props.selectedStyle) return "";
    return fonts.find((item) => item.key === key).value;
  };

  const renderSelectedFabricName = () => {
    if (!props.selectedFabric) return "";
    return fabric.find((item) => item.value === props.selectedFabric).name;
  };

  return (
    <Html>
      <Head />
      <Body style={main}>
        <Row
          style={{
            border: "1px solid #f7f7f7",
            width: "600px",
            maxWidth: "600px",
          }}
        >
          {/* First Column */}
          <Column
            style={{
              width: "200px",
              maxWidth: "200px",
              borderRight: "1px solid #f7f7f7",
              verticalAlign: "top",
              overflow: "hidden",
            }}
          >
            <Section style={track.container}>
              <Row>
                <Column>
                  <Text style={{ ...global.paragraphWithBold, fontSize: 10 }}>
                    查詢號碼
                  </Text>
                  <Text style={track.number}>{props.orderNumber}</Text>
                </Column>
                <Column align="right">
                  <Link style={global.inquirySysName}>DOS 查詢系統</Link>
                </Column>
              </Row>
            </Section>
            <Hr style={global.hr} />
            <Section style={global.defaultPadding}>
              <Column>
                <Text style={{ ...global.paragraphWithBold, fontSize: 10 }}>
                  查詢日期
                </Text>
                <Text style={track.number}>{props.orderDate}</Text>
              </Column>
            </Section>
            <Hr style={global.hr} />
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>團體名稱</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {props.groupName}
              </Text>
              <Text style={adressTitle}>訂購人姓名</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {props.customerName}
              </Text>
              <Text style={adressTitle}>聯絡電郵</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {props.email}
              </Text>
              <Text style={adressTitle}>聯絡電話</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>{props.tel}</Text>
            </Section>
            <Hr style={global.hr} />
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>備注/其他要求</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {props.notes}
              </Text>
            </Section>
          </Column>
          {/* Second Column */}
          <Column
            style={{
              width: "200px",
              maxWidth: "200px",
              verticalAlign: "top",
              overflow: "hidden",
              borderRight: "1px solid #f7f7f7",
            }}
          >
            {/* Style */}
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>衫款</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {renderStyleText()}
              </Text>
            </Section>
            <Hr style={{ ...global.hr }} />
            {/* Pattern */}
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>款式</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {renderPatternText()}
              </Text>
            </Section>
            <Hr style={{ ...global.hr }} />
            {/* Fabric */}
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>布料</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {renderSelectedFabricName()}
              </Text>
            </Section>
            <Hr style={{ ...global.hr }} />
            {/* Player Number */}
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>球員號碼字體</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {renderSelectedFont(props.selectedPlayerNumberFont)}
              </Text>
            </Section>
            <Hr style={{ ...global.hr }} />
            {/* Player Top Number Color */}
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>球員號碼(上身)顏色</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {colors[props.selectedPlayerNumberColorTop].key}
              </Text>
            </Section>
            <Hr style={{ ...global.hr }} />
            {/* Player Pants Number Color */}
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>球員號碼(下身)顏色</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {colors[props.selectedPlayerNumberColorPants].key}
              </Text>
            </Section>
            <Hr style={{ ...global.hr }} />
            {/* Team Name Font */}
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>球隊名稱字體</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {renderSelectedFont(props.selectedTeamNameFont)}
              </Text>
            </Section>
            <Hr style={{ ...global.hr }} />
            {/* Team Name */}
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>球隊名稱</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {props.teamNameText}
              </Text>
            </Section>
            <Hr style={{ ...global.hr }} />
            {/* Team Name Color */}
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>球隊名稱顏色</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {colors[props.selectedTeamNameColor].key}
              </Text>
            </Section>
            <Hr style={{ ...global.hr }} />
            {/* Player Name Font*/}
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>球員名稱字體</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {renderSelectedFont(props.selectedPlayerNameFont)}
              </Text>
            </Section>
            <Hr style={{ ...global.hr }} />
            {/* Player Name Color */}
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>球員名稱顏色</Text>
              <Text style={{ ...global.text, fontSize: 10 }}>
                {colors[props.selectedPlayerNameColor].key}
              </Text>
            </Section>
          </Column>
          {/* Third Column */}
          <Column
            style={{
              width: "200px",
              maxWidth: "200px",
              verticalAlign: "top",
              overflow: "hidden",
            }}
          >
            {/* Pattern Design */}
            <Section style={global.defaultPadding}>
              <Text style={adressTitle}>款式設計內容</Text>
              <Text style={{ ...paragraph, fontWeight: "500" }}>
                {props.selectedColorSet &&
                  getSortedSelectedColorSet().map((key) => {
                    return (
                      <>
                        <Text
                          key={key}
                          style={{ ...global.text, fontSize: 10 }}
                        >
                          {`${
                            pattern_parts[key.split("_")[0]]
                          } ${String.fromCharCode(
                            parseInt(key.split("_")[1]) + 64
                          )} - ${colors[props.selectedColorSet[key]].key}`}
                        </Text>
                        <Hr key={`${key}_hr`} style={global.hr} />
                      </>
                    );
                  })}
              </Text>
            </Section>
          </Column>
        </Row>
        <Section style={paddingY}>
          <Text style={footer.text}>
            © 2023 DOS Sportwear. All Rights Reserved.
          </Text>
        </Section>
      </Body>
    </Html>
  );
};

export default DOSOrderEmailTemplate;

const paddingX = {
  paddingLeft: "10px",
  paddingRight: "10px",
};

const paddingY = {
  paddingTop: "5px",
  paddingBottom: "5px",
};

const paragraph = {
  margin: "0",
  lineHeight: "2",
};

const marginY = {
  marginBottom: "6px",
  marginTop: "6px",
};

const global = {
  paddingX,
  paddingY,
  defaultPadding: {
    ...paddingX,
    ...paddingY,
  },
  paragraphWithBold: { ...paragraph, fontWeight: "bold" },
  heading: {
    fontSize: "16px",
    lineHeight: "1.3",
    fontWeight: "700",
    textAlign: "center",
    letterSpacing: "-1px",
  },
  text: {
    ...paragraph,
    color: "#747474",
    fontWeight: "500",
  },
  inquirySysName: {
    border: "1px solid #929292",
    fontSize: "0.5em",
    textDecoration: "none",
    padding: "10px 0px",
    display: "block",
    textAlign: "center",
    fontWeight: 500,
    color: "#000",
  },
  button: {
    border: "1px solid #929292",
    fontSize: "16px",
    textDecoration: "none",
    padding: "10px 0px",
    width: "220px",
    display: "block",
    textAlign: "center",
    fontWeight: 500,
    color: "#000",
  },
  hr: {
    borderColor: "#E5E5E5",
    margin: "0",
  },
};

const main = {
  backgroundColor: "#ffffff",
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
};

const container = {
  //   margin: "10px auto",
  width: "600px",
  border: "1px solid #E5E5E5",
};

const track = {
  container: {
    ...paddingY,
    ...paddingX,
    backgroundColor: "#F7F7F7",
  },
  number: {
    margin: "0 0 2px 0",
    fontWeight: 500,
    lineHeight: "1",
    color: "#6F6F6F",
    fontSize: "10px",
  },
};

const message = {
  padding: "40px 74px",
  textAlign: "center",
};

const adressTitle = {
  ...paragraph,
  fontSize: "10px",
  fontWeight: "bold",
};

const recomendationsText = {
  margin: "0",
  fontSize: "10px",
  lineHeight: "1",
  paddingLeft: "10px",
  paddingRight: "10px",
};

const recomendations = {
  container: {
    padding: "20px 0",
  },
  product: {
    verticalAlign: "top",
    textAlign: "left",
    paddingLeft: "2px",
    paddingRight: "2px",
  },
  title: { ...recomendationsText, paddingTop: "12px", fontWeight: "500" },
  text: {
    ...recomendationsText,
    paddingTop: "4px",
    color: "#747474",
  },
};

const menu = {
  container: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "20px",
    backgroundColor: "#F7F7F7",
  },
  content: {
    ...paddingY,
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  title: {
    paddingLeft: "20px",
    paddingRight: "20px",
    fontWeight: "bold",
  },
  text: {
    fontSize: "13.5px",
    marginTop: 0,
    fontWeight: 500,
    color: "#000",
  },
  tel: {
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "32px",
    paddingBottom: "22px",
  },
};

const categories = {
  container: {
    width: "370px",
    margin: "auto",
    paddingTop: "12px",
  },
  text: {
    fontWeight: "500",
    color: "#000",
  },
};

const footer = {
  policy: {
    width: "166px",
    margin: "auto",
  },
  text: {
    margin: "0",
    color: "#AFAFAF",
    fontSize: "13px",
    textAlign: "center",
  },
};
