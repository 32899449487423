import { Image } from "@chakra-ui/react";

export default function StyledHeader() {
  return (
    <Image
      style={{
        backgroundColor: "transparent",
        width: "1280px",
        height: "86px",
        position: "absolute",
        top: 0,
        zIndex: 99,
        pointerEvents: "none",
      }}
      src="images/ui/header-bg.png"
    />
  );
}
