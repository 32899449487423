export default function LightsAndEffects() {
  return (
    <>
      {/* Lighting setup */}
      <ambientLight intensity={0.5} color="#ffffff" />
      <directionalLight position={[-20, 0, 20]} intensity={0.6} />
      <directionalLight position={[20, 0, -20]} intensity={0.6} />
      {/* <directionalLight position={[-50, 100, 0]} intensity={0.25} /> */}
      {/* <directionalLight position={[50, -100, 0]} intensity={0.25} /> */}
    </>
  );
}
