import { Container, Grid, GridItem, Box, Text, Image } from "@chakra-ui/react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import state from "./material-panel-state";
import { fabric } from "../../../mapping";

function MaterialPanel() {
  const { t } = useTranslation();
  const { selectedFabric } = useSnapshot(state);
  return (
    <Container
      flex={1}
      bgColor={"#f9f9f9"}
      borderWidth={"1px"}
      borderColor={"brand.900"}
      borderTopRightRadius={"xl"}
      borderEndStartRadius={"xl"}
      borderEndEndRadius={"xl"}
      padding={"1rem 1.5rem"}
      overflowY="scroll"
    >
      <Grid p={"1rem 0 0 0"} templateColumns="repeat(2, 1fr)" gap={6}>
        {fabric.map((item) => (
          <GridItem key={item.key} w="8rem" h="9rem" p={"4px"}>
            <Box>
              <Box
                pos="relative"
                w="8rem"
                h="8rem"
                borderRadius={"md"}
                boxShadow={"md"}
                userSelect={"none"}
                border={selectedFabric === item.key ? "1px solid" : "0"}
                borderColor={"brand.900"}
                p={"2"}
                onClick={() => (state.selectedFabric = item.key)}
              >
                <Image w={"100%"} h={"100%"} src={item.thumbnail} />
              </Box>
              <Text
                align={"center"}
                fontSize={"small"}
                fontWeight={"bold"}
                color={"brand.900"}
                mt={"0.5rem"}
                lineHeight={"1rem"}
              >
                {t(`material_info_panel.${item.key}`)}
              </Text>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Container>
  );
}

export default forwardRef(MaterialPanel);
