import { extendTheme } from "@chakra-ui/react";

const colors = {
  black: "#0c0d0d",
  gray: {
    50: "#f6fbf9",
    100: "#e7f4f0",
    200: "#d9ece6",
    300: "#c6d8d2",
    400: "#a2b0ab",
    500: "#77817e",
    600: "#4f5654",
    700: "#333836",
    800: "#1e2020",
    900: "#181a19",
  },
  teal: {
    50: "#f5fbfb",
    100: "#d5edef",
    200: "#b1dde1",
    300: "#85c9d1",
    400: "#4aafba",
    500: "#1397a5",
    600: "#0b7b87",
    700: "#085f69",
    800: "#075058",
    900: "#064248",
  },
  cyan: {
    50: "#f7fafc",
    100: "#dcecf2",
    200: "#cce3ec",
    300: "#badae5",
    400: "#86bdd1",
    500: "#6cafc7",
    600: "#4f9fbc",
    700: "#1f85aa",
    800: "#0c6d91",
    900: "#095570",
  },
  blue: {
    50: "#f3f6fb",
    100: "#d4deef",
    200: "#b4c7e3",
    300: "#92add6",
    400: "#7295c9",
    500: "#5680bf",
    600: "#386ab4",
    700: "#134da5",
    800: "#0b3e8b",
    900: "#093372",
  },
  purple: {
    50: "#f8f6fc",
    100: "#e5dbf1",
    200: "#d1c0e7",
    300: "#b59ad8",
    400: "#a17fce",
    500: "#885cc1",
    600: "#7643b8",
    700: "#642bae",
    800: "#5213a5",
    900: "#3d0a80",
  },
  pink: {
    50: "#fcf6f8",
    100: "#f1dbe4",
    200: "#e6bece",
    300: "#d898b1",
    400: "#cd7c9d",
    500: "#bf5781",
    600: "#b53b6c",
    700: "#a5134e",
    800: "#840b3b",
    900: "#62082c",
  },
  red: {
    50: "#fbf6f5",
    100: "#f1dcd9",
    200: "#e4bcb8",
    300: "#d5968f",
    400: "#cc8078",
    500: "#bf6257",
    600: "#b44539",
    700: "#a41f10",
    800: "#8d180b",
    900: "#671208",
  },
  orange: {
    50: "#fcfaf7",
    100: "#f4ece1",
    200: "#e7d7bf",
    300: "#d4b88e",
    400: "#c39c61",
    500: "#b48239",
    600: "#a46910",
    700: "#83530a",
    800: "#684108",
    900: "#553607",
  },
  yellow: {
    50: "#fefefd",
    100: "#faf9f1",
    200: "#f0eed7",
    300: "#e4e1b8",
    400: "#d3ce8c",
    500: "#b3ab37",
    600: "#91890c",
    700: "#716a09",
    800: "#555007",
    900: "#464206",
  },
  green: {
    50: "#f8fcfa",
    100: "#d8f0e6",
    200: "#aee0cc",
    300: "#7eceae",
    400: "#49ba8c",
    500: "#0ea367",
    600: "#0b8755",
    700: "#086943",
    800: "#075637",
    900: "#06472d",
  },
  primary: {
    50: "#f0f9f9",
    100: "#c1e8e6",
    200: "#8ad2ce",
    300: "#41b7b0",
    400: "#13a59e",
    500: "#0b8c85",
    600: "#097670",
    700: "#085f5a",
    800: "#06504c",
    900: "#053a37",
  },
  brand: {
    100: "#DCEFE9",
    200: "#11C7BD",
    900: "#0DA39B",
  },
  brand_danger: { 100: "#D81A1A", 10: "#D81A1A1e" },
};

const theme = extendTheme({
  colors,
});

export default theme;
