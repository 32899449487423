import { forwardRef, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  VStack,
  UnorderedList,
  ListItem,
  Spinner,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  Text,
  Heading,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import StyledInput from "../../../components/StyledInput";
import StyledTextarea from "../../../components/StyledTextarea";
import { AddIcon } from "@chakra-ui/icons";
import {
  uploadFile,
  uploadCSV,
  addOrder,
  createOrderMail,
} from "../../../firebase.config";
import { Parser } from "@json2csv/plainjs";
import { mapping } from "../../../mapping";
import submitState from "./submit-state";
import stylePanelState from "../style-panel/style-panel-state";
import colorEditorState from "../color-editor-panel/color-editor-panel-state";
import playerNamePanelState from "../player-name-panel/player-name-panel-state";
import playerNameEditorState from "../player-name-editor/player-name-editor-state";
import teamNamePanelState from "../team-name-panel/team-name-panel-state";
import teamNameEditorState from "../team-name-editor/team-name-editor-state";
import numberPanelState from "../number-panel/number-panel-state";
import numberEditorPanelState from "../number-editor-panel/number-editor-panel-state";
import materialPanelState from "../material-panel/material-panel-state";
import batchEditorState from "../batch-panel/batch-panel-state";

function SubmitPanel(props, ref) {
  const {
    submitFormData,
    isUploadingRefImage,
    frontImageBase64,
    backImageBase64,
  } = useSnapshot(submitState);
  const { records } = useSnapshot(batchEditorState);
  const { selectedStyle, selectedPattern } = useSnapshot(stylePanelState);
  const { selectedColorSet } = useSnapshot(colorEditorState);
  const { selectedPlayerNumberFont } = useSnapshot(numberPanelState);
  const { selectedPlayerNumberColorTop, selectedPlayerNumberColorPants } =
    useSnapshot(numberEditorPanelState);
  const { selectedTeamNameFont } = useSnapshot(teamNamePanelState);
  const { teamNameText, selectedTeamNameColor } =
    useSnapshot(teamNameEditorState);
  const { selectedPlayerNameFont } = useSnapshot(playerNamePanelState);
  const { selectedPlayerNameColor } = useSnapshot(playerNameEditorState);
  const { selectedFabric } = useSnapshot(materialPanelState);

  const inputFileRef = useRef(null);
  const [isFirstTry, setFirstTry] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [submitResponse, setSubmitResponse] = useState({});
  const [isScreencapping, setScreencapping] = useState(false);
  const [frontViewImageBlob, setFrontViewImageBlob] = useState(null);
  const [backViewImageBlob, setBackViewImageBlob] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation();

  const screencap = async () => {
    // Rotate to 180 and screen cap for the back view
    props.stageRef.current.setAzimuthalAngle((180 * Math.PI) / 180);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const backImg = props.canvasRef.current.toDataURL();
    submitState.backImageBase64 = backImg;
  };

  useEffect(() => {
    if (!isScreencapping) {
      setScreencapping(true);
      screencap();
    }
  }, []);

  const isReadyToSubmit =
    submitFormData.customerEmail !== "" &&
    submitFormData.customerPhone !== "" &&
    submitFormData.customerName !== "" &&
    submitFormData.groupName !== "" &&
    frontImageBase64 !== "" &&
    backImageBase64 !== "";

  useEffect(() => {
    ref.current = submit;
  });

  const submit = async () => {
    setFirstTry(false);
    if (isReadyToSubmit) {
      submitState.isSubmitting = true;
      // Upload preview images for gathering URLs
      const { frontViewImageUrl, backViewImageUrl } =
        await uploadPreviewImages();
      try {
        const parser = new Parser({});
        const tempRecords = JSON.parse(JSON.stringify(records));
        tempRecords.map((item) => {
          delete item.id;
        });
        const csv = parser.parse(tempRecords);
        const res = await uploadCSV(csv);
        submitState.submitFormData.csvFileUrl = res.downloadUrl;
        const data = {
          ...{
            selectedStyle: selectedStyle,
            selectedPattern: selectedPattern,
            selectedPlayerNameFont: selectedPlayerNameFont,
            selectedPlayerNameColor: selectedPlayerNameColor,
            selectedTeamNameFont: selectedTeamNameFont,
            selectedTeamNameColor: selectedTeamNameColor,
            selectedColorSet: selectedColorSet,
            selectedPlayerNumberFont: selectedPlayerNumberFont,
            selectedPlayerNumberColorTop: selectedPlayerNumberColorTop,
            selectedPlayerNumberColorPants: selectedPlayerNumberColorPants,
            patternCode: mapping.find((i) => i.value === selectedPattern)
              .patternCode,
            selectedFabric: selectedFabric,
            teamNameText: teamNameText,
          },
          ...submitFormData,
          ...{ csvFileUrl: res.downloadUrl },
          ...{
            frontViewImageUrl: frontViewImageUrl.downloadUrl,
            backViewImageUrl: backViewImageUrl.downloadUrl,
          },
        };
        const addRes = await addOrder(data);
        setSubmitResponse(addRes);
        const createOrderEmailRes = await createOrderMail({
          ...data,
          ...{ id: addRes.id },
        });
      } catch (err) {
        console.error(err);
      } finally {
        submitState.isSubmitting = false;
        onOpen();
      }
    }
  };

  const uploadRefDocs = async (e) => {
    setSelectedFile(e.target.files[0]);
    // Check file size doesn't exceed 5MB
    if (e.target.files[0].size >= 5242880) return;
    submitState.submitFormData.uploadFileUrl = "";
    submitState.isUploadingRefImage = true;
    const res = await uploadFile(e.target.files[0]);
    submitState.submitFormData.uploadFileUrl = res.downloadUrl;
    submitState.submitFormData.uploadFilename = res.uploadRes.metadata.name;
    submitState.isUploadingRefImage = false;
  };

  const uploadPreviewImages = async () => {
    const frontImgBlob = await (await fetch(frontImageBase64)).blob();
    const backImgBlob = await (await fetch(backImageBase64)).blob();
    const res1 = await uploadFile(frontImgBlob);
    const res2 = await uploadFile(backImgBlob);
    return {
      frontViewImageUrl: res1,
      backViewImageUrl: res2,
    };
  };

  return (
    <Container
      flex={1}
      bgColor={"#f9f9f9"}
      borderWidth={"1px"}
      borderColor={"brand.900"}
      borderTopRightRadius={"xl"}
      borderEndStartRadius={"xl"}
      borderEndEndRadius={"xl"}
      p={6}
      maxWidth={"100%"}
      overflowY={"scroll"}
    >
      <VStack alignItems={"flex-start"} justifyContent={"flex-start"} gap={2}>
        <HStack
          w={"100%"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          gap={6}
        >
          <FormControl
            isRequired
            isInvalid={!isFirstTry && submitFormData.groupName === ""}
          >
            <FormLabel fontSize={"sm"} color={"brand.900"}>
              {t("order_form_editor.customer_group_label")}
            </FormLabel>
            <StyledInput
              color={"brand.900"}
              fontWeight={"bold"}
              placeholder={t("order_form_editor.customer_group_placeholder")}
              border={0}
              bgColor={"white"}
              borderRadius={"md"}
              boxShadow={"md"}
              _placeholder={{ color: "brand.900", fontWeight: "normal" }}
              value={submitFormData.groupName}
              onChange={(e) => {}}
              onBlur={(e) =>
                (submitState.submitFormData.groupName = e.target.value)
              }
            />
            <FormErrorMessage fontSize={"small"}>
              {t("order_form_editor.please_fill_data")}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!isFirstTry && submitFormData.customerName === ""}
          >
            <FormLabel fontSize={"sm"} color={"brand.900"}>
              {t("order_form_editor.customer_name_label")}
            </FormLabel>
            <StyledInput
              color={"brand.900"}
              fontWeight={"bold"}
              placeholder={t("order_form_editor.customer_name_placeholder")}
              border={0}
              bgColor={"white"}
              borderRadius={"md"}
              boxShadow={"md"}
              _placeholder={{ color: "brand.900", fontWeight: "normal" }}
              value={submitFormData.customerName}
              onChange={(e) => {}}
              onBlur={(e) =>
                (submitState.submitFormData.customerName = e.target.value)
              }
            />
            <FormErrorMessage fontSize={"small"}>
              {t("order_form_editor.please_fill_data")}
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <HStack
          w={"100%"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          gap={6}
        >
          <FormControl
            isRequired
            isInvalid={!isFirstTry && submitFormData.customerPhone === ""}
          >
            <FormLabel fontSize={"sm"} color={"brand.900"}>
              {t("order_form_editor.customer_tel_label")}
            </FormLabel>
            <StyledInput
              color={"brand.900"}
              fontWeight={"bold"}
              placeholder={t("order_form_editor.customer_tel_placeholder")}
              border={0}
              bgColor={"white"}
              borderRadius={"md"}
              boxShadow={"md"}
              _placeholder={{ color: "brand.900", fontWeight: "normal" }}
              value={submitFormData.customerPhone}
              onChange={(e) => {}}
              onBlur={(e) =>
                (submitState.submitFormData.customerPhone = e.target.value)
              }
            />
            <FormErrorMessage fontSize={"small"}>
              {t("order_form_editor.please_fill_data")}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!isFirstTry && submitFormData.customerEmail === ""}
          >
            <FormLabel fontSize={"sm"} color={"brand.900"}>
              {t("order_form_editor.customer_email_label")}
            </FormLabel>
            <StyledInput
              color={"brand.900"}
              fontWeight={"bold"}
              placeholder={t("order_form_editor.customer_email_placeholder")}
              border={0}
              bgColor={"white"}
              borderRadius={"md"}
              boxShadow={"md"}
              _placeholder={{ color: "brand.900", fontWeight: "normal" }}
              value={submitFormData.customerEmail}
              onChange={(e) => {}}
              onBlur={(e) =>
                (submitState.submitFormData.customerEmail = e.target.value)
              }
            />
            <FormErrorMessage fontSize={"small"}>
              {t("order_form_editor.please_fill_data")}
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <>
          <FormLabel fontSize={"sm"} color={"brand.900"}>
            {t("order_form_editor.other_info_label")}
          </FormLabel>
          <StyledTextarea
            color={"brand.900"}
            fontWeight={"bold"}
            placeholder={t("order_form_editor.other_info_placeholder")}
            border={0}
            bgColor={"white"}
            borderRadius={"md"}
            boxShadow={"md"}
            value={submitFormData.otherNotes}
            _placeholder={{ color: "brand.900", fontWeight: "normal" }}
            onChange={(e) =>
              (submitState.submitFormData.otherNotes = e.target.value)
            }
          />
        </>
        <Box>
          <FormLabel fontSize={"sm"} color={"brand.900"}>
            {t("order_form_editor.upload_label")}
          </FormLabel>
          {/* hidden input type for file */}
          <Input
            ref={inputFileRef}
            hidden
            type="file"
            accept="image/png,image/jpg,image/jpeg,application/pdf,.ai"
            onChange={uploadRefDocs}
          />
          {/* visual button to trigger the hidden input field */}
          <FormControl isInvalid={selectedFile?.size >= 5242880}>
            <HStack>
              <Button
                isDisabled={isUploadingRefImage}
                p={"1rem 2rem"}
                fontSize={16}
                color={"brand.900"}
                bgColor={"white"}
                border={"1px solid"}
                borderRadius={"xl"}
                borderColor={"brand.900"}
                onClick={() => inputFileRef.current.click()}
                _hover={
                  isUploadingRefImage
                    ? {}
                    : { bgColor: "brand.900", color: "white" }
                }
                _active={
                  isUploadingRefImage
                    ? {}
                    : { bgColor: "brand.900", color: "white" }
                }
              >
                {isUploadingRefImage ? (
                  <Spinner size={"sm"} mr={2} />
                ) : (
                  <AddIcon mr={2} />
                )}
                {t("order_form_editor.upload_action")}
              </Button>
              {submitFormData.uploadFileUrl && (
                <Link
                  color={"brand.900"}
                  target="_blank"
                  href={submitFormData.uploadFileUrl}
                >
                  {t("order_form_editor.check_uploaded_file")}
                </Link>
              )}
            </HStack>
            <FormErrorMessage fontSize="small">
              {t("order_form_editor.file_exceed_5MB")}
            </FormErrorMessage>
          </FormControl>
          <UnorderedList mt={2}>
            <ListItem fontSize={"small"}>
              {t("order_form_editor.upload_tips_1")}
            </ListItem>
            <ListItem fontSize={"small"}>
              {t("order_form_editor.upload_tips_2")}
            </ListItem>
            <ListItem fontSize={"small"}>
              {t("order_form_editor.upload_tips_3")}
            </ListItem>
          </UnorderedList>
        </Box>
      </VStack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        borderRadius={"3xl"}
        size={"3xl"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius={"3xl"}>
          <ModalHeader
            borderTopRadius={"3xl"}
            bgColor={"brand.200"}
            color="white"
            minHeight={"64px"}
          />
          <ModalBody borderRadius={"3xl"} m={"28"}>
            <VStack justifyContent={"flex-start"} gap={"0.5rem"}>
              <Heading color={"brand.900"}>
                {t("order_form_editor.submit_modal_title")}
              </Heading>
              <Text>
                {t("order_form_editor.submit_modal_order_id") +
                  submitResponse.id}
              </Text>
              <Text>{t("order_form_editor.submit_modal_msg_1")}</Text>
              <Text>{t("order_form_editor.submit_modal_msg_2")}</Text>
              <Button
                bgColor={"brand.900"}
                color="white"
                borderRadius={"xl"}
                p={"1.5rem 10rem"}
                onClick={() => {
                  onClose();
                  window.location.reload();
                }}
              >
                {t("actions.back_to_home")}
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
}
export default forwardRef(SubmitPanel);
