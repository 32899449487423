import { Container, Text } from "@chakra-ui/react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import state from "../material-panel/material-panel-state";
import { fabric } from "../../../mapping";

function MaterialInfoPanel() {
  const { t } = useTranslation();
  const { selectedFabric } = useSnapshot(state);

  return (
    <Container
      flex={1}
      bgColor={"#f9f9f9"}
      borderWidth={"1px"}
      borderColor={"brand.900"}
      borderTopRightRadius={"xl"}
      borderEndStartRadius={"xl"}
      borderEndEndRadius={"xl"}
      padding={"1rem 1.5rem"}
      overflowY="scroll"
    >
      <Text mb={"3"} fontSize={"xl"} fontWeight="bold" color="brand.900">
        {t(`material_info_panel.${selectedFabric}`)}
      </Text>
      <Text color="brand.900">
        {t(`material_info_panel.${selectedFabric}_desc`)}
      </Text>
    </Container>
  );
}

export default forwardRef(MaterialInfoPanel);
