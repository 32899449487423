import { Container, HStack, VStack } from "@chakra-ui/react";
import MainEditorTitle from "../MainEditorTitle";
import StyledTab from "../StyledTab";
import { tabs } from "../../mapping";
import { useSnapshot } from "valtio";
import { state } from "../../state";
import { forwardRef } from "react";

function MainEditorPanel(props, ref) {
  const { selectedTab } = useSnapshot(state);

  return (
    <HStack
      spacing={0}
      m={0}
      p={0}
      h={"540px"}
      w={"auto"}
      flex={1}
      alignItems="stretch"
    >
      <VStack
        h={"full"}
        pt={"3rem"}
        spacing={2}
        alignItems={"flex-end"}
        hidden={props.showTab === undefined ? true : false}
      >
        {tabs.map((item, index) => (
          <StyledTab
            key={item.key}
            iconKey={item.key}
            active={selectedTab === index}
            onClick={() => (state.selectedTab = index)}
          />
        ))}
      </VStack>
      <VStack
        w={"full"}
        h={"full"}
        flex={1}
        spacing={0}
        alignItems={"flex-start"}
      >
        <MainEditorTitle title={props.title} />
        {props.bodyComponent ?? (
          <Container
            bgColor={"#f9f9f9"}
            borderWidth={"1px"}
            borderColor={"brand.900"}
            borderTopRightRadius={"xl"}
            borderEndStartRadius={"xl"}
            borderEndEndRadius={"xl"}
            flex={1}
            padding={"1rem 1.5rem"}
            overflow={"scroll"}
          >
            {props.children}
          </Container>
        )}
      </VStack>
    </HStack>
  );
}
export default forwardRef(MainEditorPanel);
