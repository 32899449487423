import React from "react";
import {
  Decal,
  Text,
  RenderTexture,
  PerspectiveCamera,
} from "@react-three/drei";
import { fonts } from "../../../mapping";

const RenderPlayerName = ({
  baseMeshRef,
  playerName = "PLAYER NAME",
  fontSelected,
  fontColor = "white",
  position = [0, 0.31, -0.15],
  scale = 1,
  offset = { h: 0, v: 0 },
}) => {
  const font = fonts.find((i) => i.key === fontSelected).filepath;
  return (
    <Decal
      position={position}
      rotation={[0, 0, 0]}
      // scale={0.3 * scale}
      scale={0.3}
      mesh={baseMeshRef}
      // debug
    >
      <meshStandardMaterial
        roughness={0.6}
        transparent={true}
        polygonOffset
        polygonOffsetFactor={-10}
      >
        <RenderTexture attach="map" anisotropy={16}>
          <PerspectiveCamera
            makeDefault
            manual
            aspect={1 / 1}
            position={[0, 0, 1]}
          />

          <Text
            font={font}
            rotation={[0, Math.PI, 0]}
            // position={[0, -0.025, 0]}
            position={[0 + offset.h, -0.025 + offset.v, 0]}
            fontSize={0.15 * scale}
            color={fontColor}
            strokeWidth={0.01}
            strokeColor={fontColor || "#ffffff"}
          >
            {playerName}
          </Text>
        </RenderTexture>
      </meshStandardMaterial>
    </Decal>
  );
};

export default RenderPlayerName;
