import { Box, Text, VStack } from "@chakra-ui/react";
import {
  MaterialIcon,
  NameIcon,
  PlayerNumberIcon,
  StyleIcon,
  TeamNameIcon,
} from "../CustomIcons";
import { useTranslation } from "react-i18next";

export default function StyledTab(props) {
  const { t } = useTranslation();

  const renderIcon = () => {
    switch (props.iconKey) {
      default:
      case "tab_style":
        return (
          <StyleIcon
            boxSize={"2.5rem"}
            color={props.active ? "white" : "brand.900"}
          />
        );
      case "tab_number":
        return (
          <PlayerNumberIcon
            boxSize={"2.5rem"}
            color={props.active ? "white" : "brand.900"}
          />
        );
      case "tab_teamName":
        return (
          <TeamNameIcon
            boxSize={"2.5rem"}
            color={props.active ? "white" : "brand.900"}
          />
        );
      case "tab_name":
        return (
          <NameIcon
            boxSize={"2.5rem"}
            color={props.active ? "white" : "brand.900"}
          />
        );
      case "tab_material":
        return (
          <MaterialIcon
            boxSize={"2.5rem"}
            color={props.active ? "white" : "brand.900"}
          />
        );
    }
  };

  return (
    <Box
      bgColor={props.active ? "brand.900" : "white"}
      onClick={() => props.onClick()}
      p={"8px"}
      borderStartRadius={"md"}
      boxShadow={"md"}
    >
      <VStack userSelect={"none"}>
        {renderIcon()}
        <Text
          fontSize={14}
          margin={"0.2rem 0 0 0 !important"}
          color={props.active ? "white" : "brand.900"}
        >
          {t(`tabs.${props.iconKey}`)}
        </Text>
      </VStack>
    </Box>
  );
}
