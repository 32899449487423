import { forwardRef, useEffect, useState } from "react";
import { mapping } from "../../../mapping";
import { Flex, Container } from "@chakra-ui/react";
import StyledColorPicker from "../../../components/StyledColorPicker";
import { useSnapshot } from "valtio";
import state from "./color-editor-panel-state";
import stylePanelState from "../style-panel/style-panel-state";
import { useTranslation } from "react-i18next";

function ColorEditorPanel(props, ref) {
  const { selectedColorSet } = useSnapshot(state);
  const { selectedPattern } = useSnapshot(stylePanelState);
  const { t } = useTranslation();
  const [data, setData] = useState(undefined);

  const updatePanel = () => {
    setData(mapping.filter((item) => item.key === selectedPattern)[0]);
  };

  const processTitle = (key) => {
    const rtn = key.split("_");
    const num = parseInt(key.split("_")[1]);
    return `${t("pattern_parts." + rtn[0])} ${String.fromCharCode(num + 64)}`;
  };

  useEffect(() => {
    updatePanel();
  });

  useEffect(() => {
    updatePanel();
  }, [selectedPattern]);

  return (
    <Container
      flex={1}
      bgColor={"#f9f9f9"}
      borderWidth={"1px"}
      borderColor={"brand.900"}
      borderTopRightRadius={"xl"}
      borderEndStartRadius={"xl"}
      borderEndEndRadius={"xl"}
      padding={"1rem 1.5rem"}
      overflowY="scroll"
    >
      <Flex flexDir="column" gap={"20px"}>
        {data &&
          data.materials.map((item, index) => {
            return (
              <StyledColorPicker
                key={item.key}
                title={processTitle(item.key)}
                value={selectedColorSet[item.key]}
                onValueChanged={(value) =>
                  (state.selectedColorSet[item.key] = value)
                }
              />
            );
          })}
      </Flex>
    </Container>
  );
}
export default forwardRef(ColorEditorPanel);
