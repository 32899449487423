// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
import {
  getStorage,
  uploadBytes,
  uploadString,
  ref,
  getDownloadURL,
} from "firebase/storage";
import { render } from "@react-email/render";
import DOSOrderEmailTemplate from "./emails/index.jsx";
import { mapping } from "./mapping.js";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);

const uploadFile = async (file) => {
  if (!app || !storage) return;
  const fileRef = ref(
    storage,
    `public/images/${new Date().getTime()}_${file.name}`
  );
  try {
    const uploadRes = await uploadBytes(fileRef, file);
    const downloadUrl = await getDownloadURL(uploadRes.ref);
    return { uploadRes, downloadUrl };
  } catch (e) {
    throw e;
  }
};

const uploadCSV = async (data) => {
  if (!app || !storage) return;
  const fileRef = ref(storage, `public/csv/${new Date().getTime()}_report.csv`);
  try {
    const uploadRes = await uploadString(fileRef, data);
    const downloadUrl = await getDownloadURL(uploadRes.ref);
    return { uploadRes, downloadUrl };
  } catch (e) {
    throw e;
  }
};

const addOrder = async (data) => {
  if (!app || !db) return;
  const id = new Date().getTime().toString();
  const docRef = doc(db, "orders", id);
  await setDoc(docRef, { id, ...data });
  return (await getDoc(docRef)).data();
};

const createOrderMail = async (data) => {
  if (!app || !db) return;

  const emails = process.env.REACT_APP_SEND_TO_EMAIL.split(",");
  emails.forEach(async (email) => {
    const id = new Date().getTime().toString();
    const docRef = doc(db, "mail", id);

    const orderDate = new Date(parseInt(data.id));
    const templateHtml = render(
      <DOSOrderEmailTemplate
        orderNumber={data.id}
        orderDate={orderDate.toString().split(" ").slice(0, 5).join(" ")}
        groupName={data.groupName}
        customerName={data.customerName}
        email={data.customerEmail}
        tel={data.customerPhone}
        notes={data.otherNotes}
        selectedStyle={data.selectedStyle}
        selectedPattern={data.selectedPattern}
        selectedColorSet={data.selectedColorSet}
        patternCode={
          mapping.find((item) => item.value === data.selectedPattern)
            .patternCode
        }
        selectedPlayerNameFont={data.selectedPlayerNameFont}
        selectedPlayerNameColor={data.selectedPlayerNameColor}
        selectedTeamNameFont={data.selectedTeamNameFont}
        selectedTeamNameColor={data.selectedTeamNameColor}
        selectedPlayerNumberFont={data.selectedPlayerNumberFont}
        selectedPlayerNumberColorTop={data.selectedPlayerNumberColorTop}
        selectedPlayerNumberColorPants={data.selectedPlayerNumberColorPants}
        selectedFabric={data.selectedFabric}
        teamNameText={data.teamNameText}
      />,
      {
        pretty: true,
      }
    );
    await setDoc(docRef, {
      to: email,
      message: {
        subject: `Order #${data.id} - ${data.groupName} From ${data.customerName}`,
        html: templateHtml,
        attachments: [
          { filename: "report.csv", path: data.csvFileUrl },
          { filename: data.uploadFilename, path: data.uploadFileUrl },
          { filename: "front_preview.png", path: data.frontViewImageUrl },
          { filename: "back_preview.png", path: data.backViewImageUrl },
        ],
      },
    });
  });
};

const signIn = async () => {
  const signRes = await signInAnonymously(auth);
};

export {
  app,
  analytics,
  storage,
  auth,
  db,
  uploadFile,
  uploadCSV,
  addOrder,
  signIn,
  createOrderMail,
};
