import { HStack, Button, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { state } from "../../state";
import submitState from "../../pages/panels/submit-panel/submit-state";
import batchEditorState from "../../pages/panels/batch-panel/batch-panel-state";
import { useSnapshot } from "valtio";

export default function StyledFooter(props) {
  const { page } = useSnapshot(state);
  const { isSubmitting, canSubmit } = useSnapshot(submitState);
  const batchState = useSnapshot(batchEditorState);
  const { t } = useTranslation();
  return (
    <HStack p={"0.875rem 4rem 0.875rem 4rem"} justifyContent={"space-between"}>
      <Button
        bgColor={"white"}
        color={"brand.900"}
        borderRadius={"16px"}
        padding={"1.5rem 6rem"}
        outline={"1px solid"}
        outlineColor={"brand.900"}
        boxShadow={"md"}
        hidden={page === 2}
        onClick={() => props.onShowSizeChart && props.onShowSizeChart()}
        _hover={{ bg: "white" }}
        _active={{ bg: "white" }}
      >
        {t("actions.size_chart")}
      </Button>
      <HStack justifySelf={"flex-end"} marginLeft={"auto !important"}>
        <Button
          bgColor={"white"}
          color={"brand.900"}
          borderRadius={"16px"}
          padding={"1.5rem 6rem"}
          boxShadow={"md"}
          hidden={page === 0}
          onClick={() => {
            props.onPrevPage && props.onPrevPage();
            page > 0 && state.page--;
          }}
          _hover={{ bg: "white" }}
          _active={{ bg: "white" }}
        >
          {t("actions.previous")}
        </Button>
        <Button
          bgColor={"brand.900"}
          color={"white"}
          borderRadius={"16px"}
          padding={"1.5rem 6rem"}
          boxShadow={"md"}
          onClick={() => {
            props.onNextPage && props.onNextPage();
            if (page === 0 || page === 1) state.page++;
          }}
          isDisabled={
            page === 1 ? !batchState.validate : page === 2 ? !canSubmit : false
          }
          _hover={{ bg: "brand.900" }}
          _active={{ bg: "brand.900" }}
        >
          {isSubmitting ? <Spinner size={"sm"} mr={2} /> : null}
          {page === 2 ? t("actions.send_order") : t("actions.next")}
        </Button>
      </HStack>
    </HStack>
  );
}
