import { Flex, Select, Box, Grid, GridItem, Container } from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-i18next";
import state from "./player-name-panel-state";
import { font_languages, fonts } from "../../../mapping";
import { forwardRef } from "react";

function PlayerNamePanel(props, ref) {
  const { t } = useTranslation();
  const { selectedPlayerNameFontLanguage, selectedPlayerNameFont } =
    useSnapshot(state);
  return (
    <Container
      flex={1}
      bgColor={"#f9f9f9"}
      borderWidth={"1px"}
      borderColor={"brand.900"}
      borderTopRightRadius={"xl"}
      borderEndStartRadius={"xl"}
      borderEndEndRadius={"xl"}
      padding={"1rem 1.5rem"}
      overflowY="scroll"
    >
      <Flex direction={"column"}>
        <Select
          fontSize={14}
          boxShadow={"md"}
          border={"0"}
          color={"brand.900"}
          icon={<TriangleDownIcon fontSize={10} />}
          value={state.selectedPlayerNameFontLanguage}
          onChange={(e) =>
            (state.selectedPlayerNameFontLanguage = e.target.value)
          }
        >
          {font_languages.map((item) => (
            <option key={item.key} value={item.value}>
              {t(`font_languages.${item.key}`)}
            </option>
          ))}
        </Select>
        <Grid p={"1rem 0 0 0"} templateColumns="repeat(2, 1fr)" gap={6}>
          {fonts
            .filter((item) => item.type === selectedPlayerNameFontLanguage)
            .map((item) => (
              <GridItem
                key={item.key}
                w="8rem"
                h="8rem"
                p={"4px"}
                borderRadius={"md"}
                boxShadow={"md"}
                userSelect={"none"}
              >
                <Box
                  pos="relative"
                  w="100%"
                  h="100%"
                  borderRadius={"md"}
                  border={
                    selectedPlayerNameFont === item.key ? "1px solid" : "0"
                  }
                  borderColor={"brand.900"}
                  bgImage={`url(${item.thumbnail_text})`}
                  bgSize={"cover"}
                  onClick={() => (state.selectedPlayerNameFont = item.key)}
                />
              </GridItem>
            ))}
        </Grid>
      </Flex>
    </Container>
  );
}
export default forwardRef(PlayerNamePanel);
