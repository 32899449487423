import {
  Flex,
  Input,
  ListItem,
  Text,
  UnorderedList,
  Container,
} from "@chakra-ui/react";
import StyledColorPicker from "../../../components/StyledColorPicker";
import { useSnapshot } from "valtio";
import state from "./number-editor-panel-state";
import { useTranslation } from "react-i18next";
import { forwardRef } from "react";

function NumberEditorPanel(props, ref) {
  const {
    playerNumberText,
    selectedPlayerNumberColorTop,
    selectedPlayerNumberColorPants,
  } = useSnapshot(state);
  const { t } = useTranslation();

  return (
    <Container
      flex={1}
      bgColor={"#f9f9f9"}
      borderWidth={"1px"}
      borderColor={"brand.900"}
      borderTopRightRadius={"xl"}
      borderEndStartRadius={"xl"}
      borderEndEndRadius={"xl"}
      padding={"1rem 1.5rem"}
    >
      <Flex flexDir={"column"}>
        <Text color="brand.900">{t("number_panel.number_field_title")}</Text>
        <Input
          mb="0.5rem"
          maxLength={2}
          placeholder={t("number_panel.number_field_placeholder")}
          focusBorderColor="brand.900"
          border={0}
          bgColor={"white"}
          borderRadius={"md"}
          boxShadow={"md"}
          value={playerNumberText}
          onChange={(e) => {
            if (!isNaN(e.target.value.slice(-1))) {
              state.playerNumberText = e.target.value;
            }
          }}
        />
        <UnorderedList mb="1rem" fontSize={"small"}>
          <ListItem>{t("number_panel.number_restriction_tips")}</ListItem>
        </UnorderedList>
        <StyledColorPicker
          title={t("number_panel.number_color_picker_top_title")}
          value={selectedPlayerNumberColorTop}
          onValueChanged={(value) =>
            (state.selectedPlayerNumberColorTop = value)
          }
        />
        <StyledColorPicker
          title={t("number_panel.number_color_picker_pants_title")}
          value={selectedPlayerNumberColorPants}
          onValueChanged={(value) =>
            (state.selectedPlayerNumberColorPants = value)
          }
        />
      </Flex>
    </Container>
  );
}
export default forwardRef(NumberEditorPanel);
