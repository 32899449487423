import React, { useRef, useEffect } from "react";
import { Decal, useTexture } from "@react-three/drei";
import * as THREE from "three";

const RenderImage = ({
  baseMeshRef,
  url,
  position,
  rotation,
  scale = 0.05,
  color,
}) => {
  const [image] = useTexture([url]);
  return (
    <Decal
      mesh={baseMeshRef}
      position={position}
      rotation={rotation || [0, 0, 0]}
      scale={scale}
      polygonOffsetFactor={-20}
      map={image}
      color={color}
      // debug
    >
      {/* <meshStandardMaterial
        roughness={0.6}
        transparent={true}
        color={color}
        polygonOffset
        polygonOffsetFactor={-20}
        map={image}
      /> */}
    </Decal>
  );
};

export default RenderImage;
