import { useRef } from "react";
import {
  Container,
  Center,
  HStack,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Image,
} from "@chakra-ui/react";
import "../App.css";
import MainStage from "../threejs/stages/MainStage";
import { Canvas } from "@react-three/fiber";
import LightsAndEffects from "../threejs/commons/LightsAndEffect";
import StyledHeader from "../components/StyledHeader";
import MainEditorPanel from "../components/MainEditorPanel";
import { useSnapshot } from "valtio";
import { state } from "../state";
import StylePanel from "./panels/style-panel";
import ColorEditorPanel from "./panels/color-editor-panel";
import NumberPanel from "./panels/number-panel";
import TeamNamePanel from "./panels/team-name-panel";
import PlayerNamePanel from "./panels/player-name-panel";
import MaterialPanel from "./panels/material-panel";
import NumberEditorPanel from "./panels/number-editor-panel";
import PlayerNameEditor from "./panels/player-name-editor";
import TeamNameEditor from "./panels/team-name-editor";
import StyledFooter from "../components/StyledFooter";
import BatchPanel from "./panels/batch-panel";
import SubmitPanel from "./panels/submit-panel";
import { useTranslation } from "react-i18next";
import MaterialInfoPanel from "./panels/material-info-panel";
import stylePanelState from "./panels/style-panel/style-panel-state";

export default function Home() {
  const { selectedTab, panelTitle, editorTitle, page } = useSnapshot(state);
  const { selectedStyle } = useSnapshot(stylePanelState);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const activeRightSidePanelRef = useRef(null);
  const canvasRef = useRef(null);
  const stageRef = useRef(null);

  const renderLeftSidePanel = () => {
    if (page === 0) {
      const targetPanel = () => {
        if (selectedTab === 0) {
          return <StylePanel />;
        }
        if (selectedTab === 1) {
          return <NumberPanel />;
        }
        if (selectedTab === 2) {
          return <TeamNamePanel />;
        }
        if (selectedTab === 3) {
          return <PlayerNamePanel />;
        }
        if (selectedTab === 4) {
          return <MaterialPanel />;
        }
      };
      return (
        <MainEditorPanel
          showTab
          title={panelTitle}
          bodyComponent={targetPanel()}
        />
      );
    }
  };

  const renderRightSidePanel = () => {
    if (page === 0) {
      const targetEditorPanel = () => {
        if (selectedTab === 0) {
          return <ColorEditorPanel ref={activeRightSidePanelRef} />;
        }
        if (selectedTab === 1) {
          return <NumberEditorPanel ref={activeRightSidePanelRef} />;
        }
        if (selectedTab === 2) {
          return <TeamNameEditor ref={activeRightSidePanelRef} />;
        }
        if (selectedTab === 3) {
          return <PlayerNameEditor ref={activeRightSidePanelRef} />;
        }
        if (selectedTab === 4) {
          return <MaterialInfoPanel />;
        }
      };
      return (
        <MainEditorPanel
          title={editorTitle}
          bodyComponent={targetEditorPanel()}
        />
      );
    } else if (page === 1) {
      return (
        <MainEditorPanel
          title={editorTitle}
          bodyComponent={
            <BatchPanel
              ref={activeRightSidePanelRef}
              canvasRef={canvasRef}
              stageRef={stageRef}
            />
          }
        />
      );
    } else if (page === 2) {
      return (
        <MainEditorPanel
          title={editorTitle}
          bodyComponent={
            <SubmitPanel
              ref={activeRightSidePanelRef}
              canvasRef={canvasRef}
              stageRef={stageRef}
            />
          }
        ></MainEditorPanel>
      );
    }
  };

  return (
    <Center>
      <Container
        maxW={"1280px"}
        maxH={"720px"}
        w={"1280px"}
        h={"720px"}
        p={0}
        m={0}
        pt={"72px"}
        backgroundColor={"brand.100"}
        display={"flex"}
        flexDir={"column"}
      >
        <StyledHeader />
        <HStack p={"1rem 4rem"} flex={1}>
          {/* Left Side Panel - Step 1 - 5 */}
          {renderLeftSidePanel()}
          {/* 3D World */}
          <Canvas
            ref={canvasRef}
            gl={{ preserveDrawingBuffer: true }}
            style={{
              display: "block",
              backgroundColor: "brand.100",
              zIndex: 0,
              width: "360px",
              maxHeight: "540px",
              cursor: "pointer",
            }}
            flat
            dpr={[1, 2]}
            camera={{
              fov: 18.5,
              // position: [-12, 9, 10.5],
              position: [0, 0, 3],
            }}
          >
            <LightsAndEffects />
            <MainStage ref={stageRef} />
          </Canvas>
          {/* Right Side Panel - Color Editor */}
          {renderRightSidePanel()}
        </HStack>
        {/* Footer - Actions Group */}
        <StyledFooter
          onShowSizeChart={onOpen}
          onNextPage={() => {
            if (page === 2) activeRightSidePanelRef.current();
          }}
          onPrevPage={() => {}}
        />
      </Container>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        borderRadius={"3xl"}
        size={"3xl"}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius={"3xl"}>
          <ModalHeader
            borderTopRadius={"3xl"}
            bgColor={"brand.200"}
            color="white"
            minHeight={"64px"}
          >
            <Text>
              {t(`style_panel.${selectedStyle}_style`)}
              {t("actions.size_chart")}
            </Text>
          </ModalHeader>
          <ModalCloseButton color="white" size="lg" />
          <ModalBody borderRadius={"3xl"}>
            <VStack justifyContent={"flex-start"} gap={"0.5rem"}>
              <Image src={`images/sizecharts/${selectedStyle}_sizechart.jpg`} />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Center>
  );
}
