import { forwardRef } from "react";
import {
  Flex,
  ListItem,
  Text,
  UnorderedList,
  Container,
} from "@chakra-ui/react";
import StyledColorPicker from "../../../components/StyledColorPicker";
import StyledInput from "../../../components/StyledInput";
import { useSnapshot } from "valtio";
import state from "./team-name-editor-state";
import teamnamePanelState from "../team-name-panel/team-name-panel-state";
import { useTranslation } from "react-i18next";

function TeamNameEditor(props, ref) {
  const { teamNameText, selectedTeamNameColor } = useSnapshot(state);
  const { selectedTeamNameFontLanguage } = useSnapshot(teamnamePanelState);
  const { t } = useTranslation();

  return (
    <Container
      flex={1}
      bgColor={"#f9f9f9"}
      borderWidth={"1px"}
      borderColor={"brand.900"}
      borderTopRightRadius={"xl"}
      borderEndStartRadius={"xl"}
      borderEndEndRadius={"xl"}
      padding={"1rem 1.5rem"}
    >
      <Flex flexDir={"column"}>
        <Text color="brand.900">
          {t("team_name_panel.team_name_field_title")}
        </Text>
        <StyledInput
          mb="0.5rem"
          maxLength={selectedTeamNameFontLanguage === "en" ? 18 : 10}
          placeholder={t("team_name_panel.team_name_field_placeholder")}
          border={0}
          bgColor={"white"}
          borderRadius={"md"}
          boxShadow={"md"}
          value={teamNameText}
          onChange={(e) => (state.teamNameText = e.target.value)}
        />
        <UnorderedList mb="1rem" fontSize={"small"}>
          <ListItem>
            {t("team_name_panel.team_name_restriction_tips_zh")}
          </ListItem>
          <ListItem>
            {t("team_name_panel.team_name_restriction_tips_en")}
          </ListItem>
        </UnorderedList>
        <StyledColorPicker
          title={t("team_name_panel.team_name_color_picker_title")}
          value={selectedTeamNameColor}
          onValueChanged={(value) => (state.selectedTeamNameColor = value)}
        />
      </Flex>
    </Container>
  );
}
export default forwardRef(TeamNameEditor);
