const mapping = [
  {
    key: "male_vb_001",
    value: "male_vb_001",
    patternCode: "MVBJ-001",
    thumbnail: "images/thumbnails/male_vb/thumbnail_mvb_001.jpeg",
    style: "male_vb",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/male_vb/001/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/male_vb/001/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/male_vb/001/body_003.png",
      },
      {
        key: "upper_004",
        filepath: "/assets/textures/male_vb/001/body_004.png",
      },
      {
        key: "upper_005",
        filepath: "/assets/textures/male_vb/001/body_005.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/male_vb/001/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/male_vb/001/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/male_vb/001/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/male_vb/001/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/male_vb/001/shorts_002.png",
      },
    ],
    defaultColorSet: {
      collar_001: 5,
      collar_002: 21,
      pants_001: 4,
      pants_002: 5,
      sleeves_001: 4,
      upper_001: 4,
      upper_002: 5,
      upper_003: 20,
      upper_004: 3,
      upper_005: 4,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "male_vb_002",
    value: "male_vb_002",
    patternCode: "MVBJ-002",
    thumbnail: "images/thumbnails/male_vb/thumbnail_mvb_002.jpeg",
    style: "male_vb",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/male_vb/002/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/male_vb/002/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/male_vb/002/body_003.png",
      },
      {
        key: "upper_004",
        filepath: "/assets/textures/male_vb/002/body_004.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/male_vb/002/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/male_vb/002/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/male_vb/002/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/male_vb/002/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/male_vb/002/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/male_vb/002/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/male_vb/002/shorts_004.png",
      },
    ],
    defaultColorSet: {
      collar_001: 21,
      collar_002: 0,
      pants_001: 21,
      pants_002: 20,
      pants_003: 5,
      pants_004: 20,
      sleeves_001: 21,
      upper_001: 21,
      upper_002: 20,
      upper_003: 5,
      upper_004: 20,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "male_vb_003",
    value: "male_vb_003",
    patternCode: "MVBJ-003",
    thumbnail: "images/thumbnails/male_vb/thumbnail_mvb_003.jpeg",
    style: "male_vb",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/male_vb/003/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/male_vb/003/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/male_vb/003/body_003.png",
      },
      {
        key: "upper_004",
        filepath: "/assets/textures/male_vb/003/body_004.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/male_vb/003/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/male_vb/003/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/male_vb/003/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/male_vb/003/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/male_vb/003/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/male_vb/003/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/male_vb/003/shorts_004.png",
      },
    ],
    defaultColorSet: {
      collar_001: 4,
      collar_002: 10,
      pants_001: 4,
      pants_002: 4,
      pants_003: 4,
      pants_004: 10,
      sleeves_001: 4,
      upper_001: 9,
      upper_002: 10,
      upper_003: 4,
      upper_004: 4,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "male_vb_004",
    value: "male_vb_004",
    patternCode: "MVBJ-004",
    thumbnail: "images/thumbnails/male_vb/thumbnail_mvb_004.jpeg",
    style: "male_vb",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/male_vb/004/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/male_vb/004/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/male_vb/004/body_003.png",
      },
      {
        key: "upper_004",
        filepath: "/assets/textures/male_vb/004/body_004.png",
      },
      {
        key: "upper_005",
        filepath: "/assets/textures/male_vb/004/body_005.png",
      },

      {
        key: "sleeves_001",
        filepath: "/assets/textures/male_vb/004/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/male_vb/004/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/male_vb/004/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/male_vb/004/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/male_vb/004/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/male_vb/004/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/male_vb/004/shorts_004.png",
      },
      {
        key: "pants_005",
        filepath: "/assets/textures/male_vb/004/shorts_005.png",
      },
    ],
    defaultColorSet: {
      collar_001: 4,
      collar_002: 13,
      pants_001: 4,
      pants_002: 4,
      pants_003: 4,
      pants_004: 4,
      pants_005: 13,
      sleeves_001: 4,
      upper_001: 14,
      upper_002: 13,
      upper_003: 12,
      upper_004: 0,
      upper_005: 4,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "male_vb_005",
    value: "male_vb_005",
    patternCode: "MVBJ-005",
    thumbnail: "images/thumbnails/male_vb/thumbnail_mvb_005.jpeg",
    style: "male_vb",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/male_vb/005/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/male_vb/005/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/male_vb/005/body_003.png",
      },
      {
        key: "upper_004",
        filepath: "/assets/textures/male_vb/005/body_004.png",
      },

      {
        key: "sleeves_001",
        filepath: "/assets/textures/male_vb/005/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/male_vb/005/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/male_vb/005/collar_edge_002.png",
      },

      {
        key: "pants_001",
        filepath: "/assets/textures/male_vb/005/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/male_vb/005/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/male_vb/005/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/male_vb/005/shorts_004.png",
      },
    ],
    defaultColorSet: {
      collar_001: 4,
      collar_002: 0,
      pants_001: 4,
      pants_002: 4,
      pants_003: 4,
      pants_004: 23,
      sleeves_001: 23,
      upper_001: 23,
      upper_002: 22,
      upper_003: 0,
      upper_004: 23,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "female_vb_001",
    value: "female_vb_001",
    patternCode: "FVBJ-001",
    thumbnail: "images/thumbnails/female_vb/thumbnail_fvb_001.jpeg",
    style: "female_vb",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/female_vb/001/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/female_vb/001/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/female_vb/001/body_003.png",
      },
      {
        key: "upper_004",
        filepath: "/assets/textures/female_vb/001/body_004.png",
      },
      {
        key: "upper_005",
        filepath: "/assets/textures/female_vb/001/body_005.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/female_vb/001/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/female_vb/001/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/female_vb/001/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/female_vb/001/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/female_vb/001/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/female_vb/001/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/female_vb/001/shorts_004.png",
      },
      {
        key: "pants_005",
        filepath: "/assets/textures/female_vb/001/shorts_005.png",
      },
      {
        key: "pants_006",
        filepath: "/assets/textures/female_vb/001/shorts_006.png",
      },
    ],
    defaultColorSet: {
      collar_001: 8,
      collar_002: 0,
      pants_001: 21,
      pants_002: 21,
      pants_003: 8,
      pants_004: 15,
      pants_005: 0,
      pants_006: 0,
      sleeves_001: 21,
      upper_001: 21,
      upper_002: 21,
      upper_003: 8,
      upper_004: 15,
      upper_005: 0,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "female_vb_002",
    value: "female_vb_002",
    patternCode: "FVBJ-002",
    thumbnail: "images/thumbnails/female_vb/thumbnail_fvb_002.jpeg",
    style: "female_vb",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/female_vb/002/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/female_vb/002/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/female_vb/002/body_003.png",
      },
      {
        key: "upper_004",
        filepath: "/assets/textures/female_vb/002/body_004.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/female_vb/002/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/female_vb/002/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/female_vb/002/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/female_vb/002/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/female_vb/002/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/female_vb/002/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/female_vb/002/shorts_004.png",
      },
      {
        key: "pants_005",
        filepath: "/assets/textures/female_vb/002/shorts_005.png",
      },
    ],
    defaultColorSet: {
      collar_001: 14,
      collar_002: 0,
      pants_001: 4,
      pants_002: 4,
      pants_003: 4,
      pants_004: 0,
      pants_005: 13,
      sleeves_001: 4,
      upper_001: 14,
      upper_002: 13,
      upper_003: 12,
      upper_004: 0,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "female_vb_003",
    value: "female_vb_003",
    patternCode: "FVBJ-003",
    thumbnail: "images/thumbnails/female_vb/thumbnail_fvb_003.jpeg",
    style: "female_vb",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/female_vb/003/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/female_vb/003/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/female_vb/003/body_003.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/female_vb/003/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/female_vb/003/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/female_vb/003/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/female_vb/003/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/female_vb/003/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/female_vb/003/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/female_vb/003/shorts_004.png",
      },
    ],
    defaultColorSet: {
      collar_001: 19,
      collar_002: 15,
      pants_001: 4,
      pants_002: 4,
      pants_003: 19,
      pants_004: 19,
      sleeves_001: 21,
      upper_001: 21,
      upper_002: 19,
      upper_003: 15,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "female_vb_004",
    value: "female_vb_004",
    patternCode: "FVBJ-004",
    thumbnail: "images/thumbnails/female_vb/thumbnail_fvb_004.jpeg",
    style: "female_vb",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/female_vb/004/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/female_vb/004/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/female_vb/004/body_003.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/female_vb/004/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/female_vb/004/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/female_vb/004/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/female_vb/004/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/female_vb/004/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/female_vb/004/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/female_vb/004/shorts_004.png",
      },
    ],
    defaultColorSet: {
      collar_001: 21,
      collar_002: 17,
      pants_001: 0,
      pants_002: 17,
      pants_003: 0,
      pants_004: 21,
      sleeves_001: 0,
      upper_001: 0,
      upper_002: 17,
      upper_003: 0,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 21,
      playerNumberColorPants: 21,
      teamNameFont: "font_5",
      teamNameColor: 21,
      playerNameFont: "font_5",
      playerNameColor: 21,
    },
  },
  {
    key: "female_vb_005",
    value: "female_vb_005",
    patternCode: "FVBJ-005",
    thumbnail: "images/thumbnails/female_vb/thumbnail_fvb_005.jpeg",
    style: "female_vb",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/female_vb/005/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/female_vb/005/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/female_vb/005/body_003.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/female_vb/005/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/female_vb/005/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/female_vb/005/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/female_vb/005/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/female_vb/005/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/female_vb/005/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/female_vb/005/shorts_004.png",
      },
    ],
    defaultColorSet: {
      collar_001: 8,
      collar_002: 18,
      pants_001: 4,
      pants_002: 8,
      pants_003: 18,
      pants_004: 4,
      sleeves_001: 4,
      upper_001: 4,
      upper_002: 8,
      upper_003: 18,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "football_001",
    value: "football_001",
    patternCode: "FBFBJ-001",
    thumbnail: "images/thumbnails/football/thumbnail_football_001.jpeg",
    style: "football",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/football/001/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/football/001/body_002.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/football/001/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/football/001/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/football/001/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/football/001/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/football/001/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/football/001/shorts_003.png",
      },
    ],
    defaultColorSet: {
      collar_001: 4,
      collar_002: 0,
      pants_001: 4,
      pants_002: 11,
      pants_003: 11,
      sleeves_001: 11,
      upper_001: 11,
      upper_002: 4,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "football_002",
    value: "football_002",
    patternCode: "FBFBJ-002",
    thumbnail: "images/thumbnails/football/thumbnail_football_002.jpeg",
    style: "football",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/football/002/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/football/002/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/football/002/body_003.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/football/002/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/football/002/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/football/002/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/football/002/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/football/002/shorts_002.png",
      },
    ],
    defaultColorSet: {
      collar_001: 4,
      collar_002: 0,
      pants_001: 4,
      pants_002: 5,
      sleeves_001: 5,
      upper_001: 5,
      upper_002: 0,
      upper_003: 4,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "football_003",
    value: "football_003",
    patternCode: "FBFBJ-003",
    thumbnail: "images/thumbnails/football/thumbnail_football_003.jpeg",
    style: "football",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/football/003/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/football/003/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/football/003/body_003.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/football/003/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/football/003/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/football/003/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/football/003/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/football/003/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/football/003/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/football/003/shorts_004.png",
      },
    ],
    defaultColorSet: {
      collar_001: 4,
      collar_002: 0,
      pants_001: 4,
      pants_002: 4,
      pants_003: 4,
      pants_004: 2,
      sleeves_001: 4,
      upper_001: 4,
      upper_002: 2,
      upper_003: 4,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "football_004",
    value: "football_004",
    patternCode: "FBFBJ-004",
    thumbnail: "images/thumbnails/football/thumbnail_football_004.jpeg",
    style: "football",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/football/004/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/football/004/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/football/004/body_003.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/football/004/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/football/004/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/football/004/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/football/004/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/football/004/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/football/004/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/football/004/shorts_004.png",
      },
    ],
    defaultColorSet: {
      collar_001: 6,
      collar_002: 0,
      pants_001: 0,
      pants_002: 0,
      pants_003: 0,
      pants_004: 6,
      sleeves_001: 19,
      upper_001: 19,
      upper_002: 19,
      upper_003: 20,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 6,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "football_005",
    value: "football_005",
    patternCode: "FBFBJ-005",
    thumbnail: "images/thumbnails/football/thumbnail_football_005.jpeg",
    style: "football",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/football/005/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/football/005/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/football/005/body_003.png",
      },
      {
        key: "upper_004",
        filepath: "/assets/textures/football/005/body_004.png",
      },
      {
        key: "upper_005",
        filepath: "/assets/textures/football/005/body_005.png",
      },
      {
        key: "sleeves_001",
        filepath: "/assets/textures/football/005/sleeve_001.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/football/005/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/football/005/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/football/005/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/football/005/shorts_003.png",
      },
    ],
    defaultColorSet: {
      collar_001: 22,
      collar_002: 0,
      pants_001: 4,
      pants_002: 22,
      sleeves_001: 4,
      upper_001: 0,
      upper_002: 22,
      upper_003: 2,
      upper_004: 3,
      upper_005: 4,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 5,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 5,
      playerNameFont: "font_5",
      playerNameColor: 5,
    },
  },
  {
    key: "basketball_001",
    value: "basketball_001",
    patternCode: "BKBJ-001",
    thumbnail: "images/thumbnails/basketball/thumbnail_basketball_001.jpeg",
    style: "basketball",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/basketball/001/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/basketball/001/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/basketball/001/body_003.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/basketball/001/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/basketball/001/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/basketball/001/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/basketball/001/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/basketball/001/shorts_003.png",
      },
    ],
    defaultColorSet: {
      collar_001: 23,
      collar_002: 0,
      pants_001: 23,
      pants_002: 22,
      pants_003: 21,
      upper_001: 23,
      upper_002: 22,
      upper_003: 21,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "basketball_002",
    value: "basketball_002",
    patternCode: "BKBJ-002",
    thumbnail: "images/thumbnails/basketball/thumbnail_basketball_002.jpeg",
    style: "basketball",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/basketball/002/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/basketball/002/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/basketball/002/body_003.png",
      },
      {
        key: "upper_004",
        filepath: "/assets/textures/basketball/002/body_004.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/basketball/002/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/basketball/002/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/basketball/002/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/basketball/002/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/basketball/002/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/basketball/002/shorts_004.png",
      },
    ],
    defaultColorSet: {
      collar_001: 21,
      collar_002: 5,
      pants_001: 0,
      pants_002: 5,
      pants_003: 21,
      pants_004: 20,
      upper_001: 0,
      upper_002: 5,
      upper_003: 21,
      upper_004: 20,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 21,
      playerNumberColorPants: 21,
      teamNameFont: "font_5",
      teamNameColor: 21,
      playerNameFont: "font_5",
      playerNameColor: 21,
    },
  },
  {
    key: "basketball_003",
    value: "basketball_003",
    patternCode: "BKBJ-003",
    thumbnail: "images/thumbnails/basketball/thumbnail_basketball_003.jpeg",
    style: "basketball",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/basketball/003/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/basketball/003/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/basketball/003/body_003.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/basketball/003/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/basketball/003/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/basketball/003/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/basketball/003/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/basketball/003/shorts_003.png",
      },
    ],
    defaultColorSet: {
      collar_001: 21,
      collar_002: 0,
      pants_001: 21,
      pants_002: 20,
      pants_003: 19,
      upper_001: 21,
      upper_002: 20,
      upper_003: 19,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "basketball_004",
    value: "basketball_004",
    patternCode: "BKBJ-004",
    thumbnail: "images/thumbnails/basketball/thumbnail_basketball_004.jpeg",
    style: "basketball",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/basketball/004/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/basketball/004/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/basketball/004/body_003.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/basketball/004/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/basketball/004/collar_edge_002.png",
      },
      {
        key: "pants_001",
        filepath: "/assets/textures/basketball/004/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/basketball/004/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/basketball/004/shorts_003.png",
      },
    ],
    defaultColorSet: {
      collar_001: 10,
      collar_002: 0,
      pants_001: 4,
      pants_002: 10,
      pants_003: 0,
      upper_001: 4,
      upper_002: 10,
      upper_003: 0,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
  {
    key: "basketball_005",
    value: "basketball_005",
    patternCode: "BKBJ-005",
    thumbnail: "images/thumbnails/basketball/thumbnail_basketball_005.jpeg",
    style: "basketball",
    materials: [
      {
        key: "upper_001",
        filepath: "/assets/textures/basketball/005/body_001.png",
      },
      {
        key: "upper_002",
        filepath: "/assets/textures/basketball/005/body_002.png",
      },
      {
        key: "upper_003",
        filepath: "/assets/textures/basketball/005/body_003.png",
      },
      {
        key: "upper_004",
        filepath: "/assets/textures/basketball/005/body_004.png",
      },
      {
        key: "collar_001",
        filepath: "/assets/textures/basketball/005/collar_edge_001.png",
      },
      {
        key: "collar_002",
        filepath: "/assets/textures/basketball/005/collar_edge_002.png",
      },

      {
        key: "pants_001",
        filepath: "/assets/textures/basketball/005/shorts_001.png",
      },
      {
        key: "pants_002",
        filepath: "/assets/textures/basketball/005/shorts_002.png",
      },
      {
        key: "pants_003",
        filepath: "/assets/textures/basketball/005/shorts_003.png",
      },
      {
        key: "pants_004",
        filepath: "/assets/textures/basketball/005/shorts_004.png",
      },
    ],
    defaultColorSet: {
      collar_001: 14,
      collar_002: 12,
      pants_001: 14,
      pants_002: 12,
      pants_003: 2,
      pants_004: 3,
      upper_001: 14,
      upper_002: 12,
      upper_003: 2,
      upper_004: 3,
    },
    defaultFontSet: {
      playerNumberFont: "font_5",
      playerNumberColorTop: 0,
      playerNumberColorPants: 0,
      teamNameFont: "font_5",
      teamNameColor: 0,
      playerNameFont: "font_5",
      playerNameColor: 0,
    },
  },
];

const tabs = [
  { key: "tab_style", value: 0 },
  { key: "tab_number", value: 1 },
  { key: "tab_teamName", value: 2 },
  { key: "tab_name", value: 3 },
  { key: "tab_material", value: 4 },
];

const sportswear_styles = [
  { key: "football_style", value: "football" },
  { key: "basketball_style", value: "basketball" },
  { key: "male_vb_style", value: "male_vb" },
  { key: "female_vb_style", value: "female_vb" },
];

const font_languages = [
  {
    key: "en",
    value: "en",
  },
  {
    key: "zh",
    value: "zh",
  },
];

const fonts = [
  {
    key: "font_1",
    value: "28 DAYS LATER",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_1.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_1.png",
    filepath: "assets/fonts/28 DAYS LATER.TTF",
    fontScale: 1,
    offset: { h: 0, v: 0.02 },
  },
  {
    key: "font_2",
    value: "A LAME BEE",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_2.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_2.png",
    filepath: "assets/fonts/A LAME BEE.TTF",
    fontScale: 1,
    offset: { h: 0, v: -0.09 },
  },
  {
    key: "font_3",
    value: "ADIDAS_UNITY",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_3.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_3.png",
    filepath: "assets/fonts/ADIDAS_UNITY.TTF",
    fontScale: 0.85,
    offset: { h: 0, v: -0.05 },
  },
  {
    key: "font_4",
    value: "ALBA",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_4.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_4.png",
    filepath: "assets/fonts/ALBA_.TTF",
    fontScale: 0.7,
    offset: { h: 0, v: 0 },
  },
  {
    key: "font_5",
    value: "ALDOTHEAPACHE",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_5.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_5.png",
    filepath: "assets/fonts/ALDOTHEAPACHE.TTF",
    fontScale: 1,
    offset: { h: 0, v: 0 },
  },
  {
    key: "font_6",
    value: "BARCLAYS PREMIER LEAGUE",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_6.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_6.png",
    filepath: "assets/fonts/BARCLAYS PREMIER LEAGUE.TTF",
    fontScale: 0.85,
    offset: { h: 0, v: 0.05 },
  },
  {
    key: "font_7",
    value: "BRUSHSCI",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_7.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_7.png",
    filepath: "assets/fonts/BRUSHSCI.TTF",
    fontScale: 1,
    offset: { h: 0.03, v: -0.1 },
  },
  {
    key: "font_8",
    value: "潮字社凌渡鯤鵬繁 常規",
    type: "zh",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_8.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_8.png",
    filepath: "assets/fonts/潮字社凌渡鯤鵬繁 常規.ttf",
    fontScale: 0.9,
    offset: { h: 0, v: 0.05 },
  },
  {
    key: "font_9",
    value: "華康硬黑體",
    type: "zh",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_9.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_9.png",
    filepath: "assets/fonts/華康硬黑體.TTF",
    fontScale: 1,
    offset: { h: 0, v: -0.1 },
  },
  {
    key: "font_10",
    value: "DFFT",
    type: "zh",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_10.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_10.png",
    filepath: "assets/fonts/DFFT.TTC",
    fontScale: 1,
    offset: { h: 0, v: 0 },
  },
  {
    key: "font_11",
    value: "HATTEN",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_11.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_11.png",
    filepath: "assets/fonts/HATTEN.TTF",
    fontScale: 1,
    offset: { h: 0, v: 0.08 },
  },
  {
    key: "font_12",
    value: "LOBSTER 1.4",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_12.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_12.png",
    filepath: "assets/fonts/LOBSTER 1.4.OTF",
    fontScale: 0.9,
    offset: { h: 0, v: -0.1 },
  },
  {
    key: "font_13",
    value: "MAASS SLICER ITALIC",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_13.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_13.png",
    filepath: "assets/fonts/MAASS SLICER ITALIC.TTF",
    fontScale: 0.7,
    offset: { h: -0.1, v: 0 },
  },
  {
    key: "font_14",
    value: "MATTHAN SANS NC",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_14.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_14.png",
    filepath: "assets/fonts/MATTHAN SANS NC.TTF",
    fontScale: 1,
    offset: { h: 0, v: -0.08 },
  },
  {
    key: "font_15",
    value: "潮字社小石繁 常規",
    type: "zh",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_15.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_15.png",
    filepath: "assets/fonts/潮字社小石繁 常規.TTF",
    fontScale: 0.8,
    offset: { h: 0, v: 0.08 },
  },
  {
    key: "font_16",
    value: "NEW ATHLETIC M54",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_16.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_16.png",
    filepath: "assets/fonts/NEW ATHLETIC M54.TTF",
    fontScale: 0.9,
    offset: { h: 0, v: 0.03 },
  },
  {
    key: "font_17",
    value: "PORNSA",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_17.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_17.png",
    filepath: "assets/fonts/PORNSA_.TTF",
    fontScale: 0.9,
    offset: { h: 0.025, v: 0 },
  },
  {
    key: "font_18",
    value: "SF COLLEGIATE SOLID",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_18.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_18.png",
    filepath: "assets/fonts/SF COLLEGIATE SOLID.TTF",
    fontScale: 1,
    offset: { h: 0, v: 0 },
  },
  {
    key: "font_19",
    value: "SF COLLEGIATE",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_19.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_19.png",
    filepath: "assets/fonts/SF COLLEGIATE.TTF",
    fontScale: 0.9,
    offset: { h: 0, v: -0.05 },
  },
  {
    key: "font_20",
    value: "SIGNPAINTERHOUSESLANT",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_20.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_20.png",
    filepath: "assets/fonts/SIGNPAINTERHOUSESLANT.TTF",
    fontScale: 1.05,
    offset: { h: 0, v: 0.02 },
  },
  {
    key: "font_21",
    value: "ZRNIC RG",
    type: "en",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_21.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_21.png",
    filepath: "assets/fonts/ZRNIC RG.OTF",
    fontScale: 1,
    offset: { h: 0, v: -0.08 },
  },
  {
    key: "font_22",
    value: "方正行階",
    type: "zh",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_22.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_22.png",
    filepath: "assets/fonts/方正行階.TTF",
    fontScale: 0.9,
    offset: { h: 0, v: 0 },
  },
  {
    key: "font_23",
    value: "華康宗楷體W7",
    type: "zh",
    thumbnail_text: "images/thumbnails/font/thumbnail_font_text_23.png",
    thumbnail_number: "images/thumbnails/font/thumbnail_font_number_23.png",
    filepath: "assets/fonts/華康宗楷體W7.TTC",
    fontScale: 1,
    offset: { h: 0, v: 0.08 },
  },
];

const colors = [
  {
    key: "white",
    userColorCode: "#fefefe",
    printingColorCode: "#fefefe",
  },
  {
    key: "7502_pc",
    userColorCode: "#eedd9a",
    printingColorCode: "#eedd9a",
  },
  {
    key: "ci_gy_6_pc",
    userColorCode: "#a6a9b0",
    printingColorCode: "#a6a9b0",
  },
  {
    key: "ci_gy_10_pc",
    userColorCode: "#5d5f67",
    printingColorCode: "#5d5f67",
  },
  {
    key: "black",
    userColorCode: "#221814",
    printingColorCode: "#221814",
  },
  {
    key: "199_pc",
    userColorCode: "#e50011",
    printingColorCode: "#e50011",
  },
  {
    key: "1955_pc",
    userColorCode: "#9d0039",
    printingColorCode: "#9d0039",
  },
  {
    key: "1895_pc",
    userColorCode: "#f7ccdd",
    printingColorCode: "#f7ccdd",
  },
  {
    key: "rub_red_pc",
    userColorCode: "#e1005c",
    printingColorCode: "#e1005c",
  },
  {
    key: "or_021_pc",
    userColorCode: "#ed7000",
    printingColorCode: "#ed7000",
  },
  {
    key: "124_pc",
    userColorCode: "#f5a500",
    printingColorCode: "#f5a500",
  },
  {
    key: "pro_yel_pc",
    userColorCode: "#fdea00",
    printingColorCode: "#fdea00",
  },
  {
    key: "361_pc",
    userColorCode: "#539c2b",
    printingColorCode: "#539c2b",
  },
  {
    key: "356_pc",
    userColorCode: "#007c3d",
    printingColorCode: "#007c3d",
  },
  {
    key: "3435_pc",
    userColorCode: "#062c06",
    printingColorCode: "#062c06",
  },
  {
    key: "3262_pc",
    userColorCode: "#19acae",
    printingColorCode: "#19acae",
  },
  {
    key: "319_pc",
    userColorCode: "#6dc5d0",
    printingColorCode: "#6dc5d0",
  },
  {
    key: "2905_pc",
    userColorCode: "#85c0de",
    printingColorCode: "#85c0de",
  },
  {
    key: "pro_blue_pc",
    userColorCode: "#0081c2",
    printingColorCode: "#0081c2",
  },
  {
    key: "2945_pc",
    userColorCode: "#005f9a",
    printingColorCode: "#005f9a",
  },
  {
    key: "2955_pc",
    userColorCode: "#003d6f",
    printingColorCode: "#003d6f",
  },
  {
    key: "2965_pc",
    userColorCode: "#002a4f",
    printingColorCode: "#002a4f",
  },
  {
    key: "513_pc",
    userColorCode: "#6f0e49",
    printingColorCode: "#6f0e49",
  },
  {
    key: "266_pc",
    userColorCode: "#4a0c5b",
    printingColorCode: "#4a0c5b",
  },
];

const sizes = [
  { key: "4", value: "4" },
  { key: "5", value: "5" },
  { key: "6", value: "6" },
  { key: "2xs", value: "2xs" },
  { key: "xs", value: "xs" },
  { key: "s", value: "s" },
  { key: "m", value: "m" },
  { key: "l", value: "l" },
  { key: "xl", value: "xl" },
  { key: "2xl", value: "2xl" },
];

const sex = [
  { key: "male", value: "male" },
  { key: "female", value: "female" },
];

const fabric = [
  {
    key: "fabric_001",
    value: "fabric_001",
    name: "301 小星點運動布",
    thumbnail: "images/thumbnails/fabric/thumbnail_fabric_001.jpeg",
  },
  {
    key: "fabric_002",
    value: "fabric_002",
    name: "8988 鳥眼底運動布",
    thumbnail: "images/thumbnails/fabric/thumbnail_fabric_002.jpeg",
  },
  {
    key: "fabric_003",
    value: "fabric_003",
    name: "6218 提花運動布",
    thumbnail: "images/thumbnails/fabric/thumbnail_fabric_003.jpeg",
  },
  {
    key: "fabric_004",
    value: "fabric_004",
    name: "5954 磚塊運動布",
    thumbnail: "images/thumbnails/fabric/thumbnail_fabric_004.jpeg",
  },
];

export {
  mapping,
  tabs,
  sportswear_styles,
  colors,
  font_languages,
  fonts,
  sizes,
  sex,
  fabric,
};
