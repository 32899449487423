/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 Football_final.glb --transform
*/

import React, { useRef, useMemo } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import MaterialLayer from "./layers/MaterialLayer";
import { useSnapshot } from "valtio";
import { mapping, colors, fonts } from "../../mapping";
import RenderNumber from "./layers/RenderNumber";
import RenderTeamName from "./layers/RenderTeamName";
import RenderPlayerName from "./layers/RenderPlayerName";
import RenderImage from "./layers/RenderImage";

import stylePanelState from "../../pages/panels/style-panel/style-panel-state";
import colorEditorState from "../../pages/panels/color-editor-panel/color-editor-panel-state";
import playerNamePanelState from "../../pages/panels/player-name-panel/player-name-panel-state";
import playerNameEditorState from "../../pages/panels/player-name-editor/player-name-editor-state";
import teamNamePanelState from "../../pages/panels/team-name-panel/team-name-panel-state";
import teamNameEditorState from "../../pages/panels/team-name-editor/team-name-editor-state";
import numberPanelState from "../../pages/panels/number-panel/number-panel-state";
import numberEditorPanelState from "../../pages/panels/number-editor-panel/number-editor-panel-state";

export default function Football(props) {
  const { selectedPattern } = useSnapshot(stylePanelState);
  const { selectedColorSet } = useSnapshot(colorEditorState);
  const { selectedPlayerNumberFont } = useSnapshot(numberPanelState);
  const {
    playerNumberText,
    selectedPlayerNumberColorTop,
    selectedPlayerNumberColorPants,
  } = useSnapshot(numberEditorPanelState);
  const { selectedTeamNameFont } = useSnapshot(teamNamePanelState);
  const { teamNameText, selectedTeamNameColor } =
    useSnapshot(teamNameEditorState);
  const { selectedPlayerNameFont } = useSnapshot(playerNamePanelState);
  const { playerNameText, selectedPlayerNameColor } = useSnapshot(
    playerNameEditorState
  );

  const { nodes, materials } = useGLTF(
    "/assets/models/Football_final-transformed.glb"
  );
  const baseMeshRef = useRef(null);

  const selectedJersey = mapping.find((i) => i.key === selectedPattern);

  const layers = useTexture(
    selectedJersey?.materials
      .filter((i) => i.filepath !== "")
      .map((i) => i.filepath)
  );

  const layersData = useMemo(
    () =>
      selectedJersey.materials.map((i, idx) => {
        const colorObj = colors[selectedColorSet[i.key]];
        return { map: layers[idx], color: colorObj?.printingColorCode };
      }),
    [layers, selectedColorSet, selectedJersey.materials]
  );

  const isFileComplete = layers.length === Object.keys(layersData).length;
  const fontOffset = fonts.find(
    (f) => f.key === selectedPlayerNumberFont
  ).offset;
  const RenderDecal = ({}) => (
    <>
      <RenderNumber
        baseMeshRef={baseMeshRef}
        side={"front"}
        number={playerNumberText}
        fontSelected={selectedPlayerNumberFont}
        fontColor={colors[selectedPlayerNumberColorTop].printingColorCode}
        scale={
          fonts.find((f) => f.key === selectedPlayerNumberFont).fontScale * 0.55
        }
        offset={fontOffset}
      />
      <RenderNumber
        baseMeshRef={baseMeshRef}
        side={"front"}
        number={playerNumberText}
        fontSelected={selectedPlayerNumberFont}
        fontColor={colors[selectedPlayerNumberColorPants].printingColorCode}
        isPant
        scale={
          fonts.find((f) => f.key === selectedPlayerNumberFont).fontScale * 0.8
        }
        position={[0.12, -0.39, 0.1]}
        offset={fontOffset}
      />
      <RenderNumber
        baseMeshRef={baseMeshRef}
        side={"back"}
        number={playerNumberText}
        fontSelected={selectedPlayerNumberFont}
        fontColor={colors[selectedPlayerNumberColorTop].printingColorCode}
        scale={fonts.find((f) => f.key === selectedPlayerNumberFont).fontScale}
        position={[0, 0.15, -0.15]}
        offset={fontOffset}
      />
      <RenderTeamName
        baseMeshRef={baseMeshRef}
        teamName={teamNameText}
        fontSelected={selectedTeamNameFont}
        fontColor={colors[selectedTeamNameColor].printingColorCode}
        position={[0, 0.215, 0.14]}
        scale={fonts.find((f) => f.key === selectedTeamNameFont).fontScale}
      />
      <RenderPlayerName
        baseMeshRef={baseMeshRef}
        playerName={playerNameText}
        fontSelected={selectedPlayerNameFont}
        fontColor={colors[selectedPlayerNameColor].printingColorCode}
        position={[0, 0.3, -0.15]}
        scale={fonts.find((f) => f.key === selectedPlayerNameFont).fontScale}
      />
      <RenderImage
        baseMeshRef={baseMeshRef}
        url={"/assets/logo/brand.png"}
        position={[-0.1, 0.29, 0.06]}
        scale={0.06}
        color={colors[selectedPlayerNumberColorTop].printingColorCode}
      />
      <RenderImage
        baseMeshRef={baseMeshRef}
        url={"/assets/logo/brand.png"}
        position={[-0.12, -0.395, 0.09]}
        scale={0.06}
        color={colors[selectedPlayerNumberColorPants].printingColorCode}
      />
    </>
  );

  return (
    <group {...props} dispose={null}>
      <mesh
        ref={baseMeshRef}
        geometry={nodes.FootBall_Low.geometry}
        // material={materials.BoyVolly}
      >
        {isFileComplete &&
          layersData?.map((data, idx) => (
            <MaterialLayer
              key={idx}
              geometry={nodes.FootBall_Low.geometry}
              map={data.map}
              // normalMap={materials.BoyVolly.normalMap}
              colorApplied={data.color}
            />
          ))}
      </mesh>
      {isFileComplete && <RenderDecal />}
    </group>
  );
}

useGLTF.preload("/assets/models/Football_final-transformed.glb");
