import { forwardRef } from "react";
import { Select, Grid, GridItem, Box, Flex, Container } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TriangleDownIcon } from "@chakra-ui/icons";
import { mapping, sportswear_styles } from "../../../mapping";
import state from "./style-panel-state";
import colorEditorState from "../color-editor-panel/color-editor-panel-state";
import numberPanelState from "../number-panel/number-panel-state";
import numberEditorState from "../number-editor-panel/number-editor-panel-state";
import teamNamePanelState from "../team-name-panel/team-name-panel-state";
import teamNameEditorState from "../team-name-editor/team-name-editor-state";
import playerNamePanelState from "../player-name-panel/player-name-panel-state";
import playerNameEditorState from "../player-name-editor/player-name-editor-state";
import { useSnapshot } from "valtio";

function StylePanel(props, ref) {
  const { t } = useTranslation();
  const { selectedStyle, selectedPattern } = useSnapshot(state);

  const applyDefaultColorSets = () => {
    colorEditorState.selectedColorSet = JSON.parse(
      JSON.stringify(
        mapping.find((map) => map.key === state.selectedPattern).defaultColorSet
      )
    );
    // Set default font and colour for player number, name and team name
    const fontSet = JSON.parse(
      JSON.stringify(
        mapping.find((map) => map.key === state.selectedPattern).defaultFontSet
      )
    );
    // Player Number
    numberPanelState.selectedPlayerNumberFont = fontSet.playerNumberFont;
    numberEditorState.selectedPlayerNumberColorTop =
      fontSet.playerNumberColorTop;
    numberEditorState.selectedPlayerNumberColorPants =
      fontSet.playerNumberColorPants;
    // Team name
    teamNamePanelState.selectedTeamNameFont = fontSet.teamNameFont;
    teamNameEditorState.selectedTeamNameColor = fontSet.teamNameColor;
    // Player name
    playerNamePanelState.selectedPlayerNameFont = fontSet.playerNameFont;
    playerNameEditorState.selectedPlayerNameColor = fontSet.playerNameColor;
  };

  return (
    <Container
      flex={1}
      bgColor={"#f9f9f9"}
      borderWidth={"1px"}
      borderColor={"brand.900"}
      borderTopRightRadius={"xl"}
      borderEndStartRadius={"xl"}
      borderEndEndRadius={"xl"}
      padding={"1rem 1.5rem"}
      overflowY="scroll"
    >
      <Flex direction={"column"}>
        <Select
          fontSize={14}
          boxShadow={"md"}
          border={"0"}
          color={"brand.900"}
          icon={<TriangleDownIcon fontSize={10} />}
          value={state.selectedStyle}
          onChange={(e) => {
            // Change selected style will also update the selected pattern as well
            state.selectedStyle = e.target.selectedOptions[0].value;
            state.selectedPattern = mapping.find(
              (item) => item.style === e.target.selectedOptions[0].value
            ).key;
            applyDefaultColorSets();
          }}
        >
          {sportswear_styles.map((item) => (
            <option key={item.key} value={item.value}>
              {t(`style_panel.${item.key}`)}
            </option>
          ))}
        </Select>
        <Grid p={"1rem 0 0 0"} templateColumns="repeat(2, 1fr)" gap={6}>
          {mapping
            .filter((item) => item.style === selectedStyle)
            .map((item) => (
              <GridItem
                key={item.key}
                w="8rem"
                h="8rem"
                p={"4px"}
                borderRadius={"md"}
                boxShadow={"md"}
                userSelect={"none"}
              >
                <Box
                  pos="relative"
                  w="100%"
                  h="100%"
                  borderRadius={"md"}
                  border={selectedPattern === item.key ? "1px solid" : "0"}
                  borderColor={"brand.900"}
                  bgImage={`url(${item.thumbnail})`}
                  bgSize={"cover"}
                  onClick={() => {
                    // Set selected pattern index
                    state.selectedPattern = item.key;
                    applyDefaultColorSets();
                  }}
                />
              </GridItem>
            ))}
        </Grid>
      </Flex>
    </Container>
  );
}

export default forwardRef(StylePanel);
