import { Flex, HStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export default function MainEditorTitle(props) {
  const { t } = useTranslation();
  return (
    <HStack>
      <Flex
        borderRadius={"1rem 0 0 0"}
        bgColor={"brand.900"}
        minW={"32"}
        height={"2.5rem"}
        m={0}
        pr={"2.5rem"}
        pl={"1rem"}
        textColor={"white"}
        alignItems={"center"}
      >
        {t(props.title)}
      </Flex>
      <div
        style={{
          position: "relative",
          width: 0,
          height: 0,
          borderStyle: "solid",
          borderWidth: "2.5rem 0 0 2.5rem",
          borderColor: "transparent transparent transparent #0DA39B",
          margin: 0,
        }}
      ></div>
    </HStack>
  );
}
